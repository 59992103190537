import React,{useEffect} from 'react';

const SearchBar = ({ isBoxCollapseOpen, onClose }) => {
  useEffect(() => {
      if (isBoxCollapseOpen) {
        document.body.classList.add('box-collapse-open');
        document.body.classList.remove('box-collapse-closed');
      }
      else{
      document.body.classList.remove('box-collapse-open');
      document.body.classList.add('box-collapse-closed');
      onClose();
      }
  }, [isBoxCollapseOpen]);
  return (
    <>
    <div className="click-closed"></div>
    <div className="box-collapse">
      <div className="title-box-d">
        <h3 className="title-d">Search Property</h3>
      </div>
      <span className="close-box-collapse right-boxed bi bi-x" onClick={onClose}></span>
      <div className="box-collapse-wrap form">
        <form className="form-a" action='/carirumah'>
          <div className="row">
            <div className="col-md-12 mb-2">
              <div className="form-group">
                <label className="pb-2" htmlFor="keyword">Keyword</label>
                <input type="text" className="form-control form-control-lg form-control-a" placeholder="Keyword" />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="form-group mt-3">
                <label className="pb-2" htmlFor="city">City</label>
                <select className="form-control form-select form-control-a" id="city">
                  <option>All City</option>
                  
                </select>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="form-group mt-3">
                <label className="pb-2" htmlFor="bedrooms">Bedrooms</label>
                <select className="form-control form-select form-control-a" id="bedrooms">
                  <option>Any</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="form-group mt-3">
                <label className="pb-2" htmlFor="minPrice">Min Price</label>
                <select className="form-control form-select form-control-a" id="minPrice">
                  <option>Unlimited</option>
                  <option>$50,000</option>
                  <option>$100,000</option>
                  <option>$150,000</option>
                  <option>$200,000</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="form-group mt-3">
                <label className="pb-2" htmlFor="bathrooms">Bathrooms</label>
                <select className="form-control form-select form-control-a" id="bathrooms">
                  <option>Any</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="form-group mt-3">
                <label className="pb-2" htmlFor="maxPrice">Max Price</label>
                <select className="form-control form-select form-control-a" id="maxPrice">
                  <option>Unlimited</option>
                  <option>$50,000</option>
                  <option>$100,000</option>
                  <option>$150,000</option>
                  <option>$200,000</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <button type="submit" className="btn btn-b">Search Property</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default SearchBar;
