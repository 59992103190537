import React, { useState, useEffect, useRef } from "react";// Sesuaikan dengan library yang Anda gunakan
import { db, storage } from "../../firebase";
import { getDoc,getDocs, doc, collection, setDoc,deleteDoc, query, where  } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Akun } from "../../state/akun";
import { ref, uploadBytes,getDownloadURL, deleteObject } from "firebase/storage";// Sesuaikan dengan konfigurasi Firebase Anda
import SimulasiKPR from "./simulasiKPR";
import { MapContainer, TileLayer, useMap, Marker, pop } from 'react-leaflet';
import { LatLng, Map, latLng } from "leaflet";
import { Swiper, SwiperSlide } from 'swiper/react';
import { OpenStreetMapProvider,GeoSearchControl } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet-geosearch/dist/geosearch.js';
import numeral from 'numeral';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { ImageGroup, Image } from 'react-fullscreen-image';


const PropertiRumah = () => {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const idRumah = param.idrumah;
  const [penjual, setPenjual] = useState(
    {
      nama:'',
      email:'',
      telepon:'',
      instagram:'',
      linkedIn:'',
      tiktok:'',
      facebook:'',
      fotourl:'',
      foto:'',
      deskripsi_pribadi:'',
      youtube:'',
      x:'',
    }
  )

  const [Properti, setProperti] = useState(
    {
      id:'',
      tanggalupdate: "",
      listrik:'',
      air:'',
      sertifikat:'',
      nama: '',
      harga:'',
      deskripsi:'',
      uid:'',
      lokasi:{
        lokasitempat:'',
        latitude:'-6.424438610427816',
        longitude:'106.74334179629714'
      },
      statusRumah:'',
      area : {
        luasTanah:'',
        luasBangunan:'',
        lebarDepan:''
      },
      kamarTidur:'',
      kamarMandi:'',
      fotorumah: [],
      fotodenah: {
        namafile:'',
        urldownload:''
      },
      fasilitas: []
  });
  useEffect(() => {
        try {
          const docRef = doc(db, "properti", idRumah);
          const docSnap = getDoc(docRef).then(async (document)=>{
            if (document.exists()) {
              const data = document.data();
              setProperti((prevData) => ({
                ...prevData,
                id:data.id||'',
                tanggalupdate:data.tanggalupdate||'',
                listrik:data.listrik||'',
                air:data.air||'',
                sertifikat:data.sertifikat||'',
                nama: data.nama||'',
                harga:data.harga||'',
                deskripsi:data.deskripsi||'',
                lokasi:data.lokasi||{
                  lokasitempat:'',
                  latitude:'',
                  longitude:''
                },
                statusRumah:data.statusRumah||'',
                area :data.area|| {
                  luasTanah:'',
                  luasBangunan:'',
                  lebarDepan:''
                },
                kamarTidur:data.kamarTidur||'',
                kamarMandi:data.kamarMandi||'',
                fotorumah: data.fotorumah||[],
                fotodenah: data.fotodenah||'',
                fasilitas: data.fasilitas||[],
                uid:data.uid||''
              }));
              const sellerRef = doc(db, "Users", data.uid);
              const docSnap = getDoc(sellerRef).then(async (document)=>{
                if (document.exists()) {
                  const data = document.data();
                  setPenjual((prevData) => (
                    {
                      ...prevData,
                      nama:data.nama||'',
                      email:data.email||'',
                      telepon:data.telepon||'',
                      instagram:data.instagram||'',
                      linkedIn:data.linkedIn||'',
                      facebook:data.facebook||'',
                      fotourl:data.fotourl||'',
                      deskripsi_pribadi:data.deskripsi_pribadi||'',
                      youtube:data.youtube||'',
                      x:data.x||'',
                    }
                  )
                  )
                }});
            } else {
              /* */
            }
          })
        } catch (error) {
          console.error('Error fetching properti data:', error);
        }
    }, [idRumah]);
    const [fullscreen, setFullscreen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const handleImageClick = (index) => {
      setActiveIndex(index);
      setFullscreen(true);
    };
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
      cursor: 'pointer',
    };
  
    const fullscreenOverlayStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
    };
  
    const fullscreenImageStyle = {
      maxWidth: 'none',
      maxHeight: 'none',
    };
    return(
      <>
      {fullscreen ? (
        <div className="fullscreen-overlay center" style={fullscreen ? fullscreenOverlayStyle : containerStyle}>
          <img
            src={Properti.fotorumah[activeIndex].urldownload}
            alt={`Fullscreen ${activeIndex}`}
            onClick={() => setFullscreen(false)}
            className="center"
            style={fullscreen ? fullscreenImageStyle : {}}
          />
        </div>
      ) : (
        <main id="main"> 
    <section className="intro-single">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div  >
              <h1 className="title-single">{Properti.nama}</h1>
              <span className="color-text-a">{Properti.lokasi.lokasitempat}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="property-single nav-arrow-b">
      <div className="container">
        <div className="row justify-content-left">

        </div>

        <div className="row">
          <div className="col-sm-12">

            <div className="row justify-content-between">
              <div className="col-lg-4">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="swiper"
              >
                {Properti.fotorumah.map((file, index) => (
                  <SwiperSlide className="swiper-wrapper center" key={index}>
                          <img src={file.urldownload} onClick={() => handleImageClick(index)} className="center" alt="" style={{
                            height:"400px",
                            width: '100%',
                          objectFit: 'cover'}}/>

                  </SwiperSlide>
                          ))}
              </Swiper>
                <div className="property-price d-flex justify-content-center">
                  <div className="card-header-c d-flex">
                    <div className="card-box-ico">
                      <span className="bi bi-cash" style={{fontSize: '3rem'}}>Rp.</span>
                    </div>
                    <div className="card-title-c align-self-center">
                      <h1 className="title-c" style={{fontSize: '2.5rem'}}>{Properti.harga}</h1>
                    </div>
                  </div>
                </div>
                <div className="property-summary">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="title-box-d section-t4">
                        <h3 className="title-d">Quick Summary</h3>
                      </div>
                    </div>
                  </div>
                  <div className="summary-list">
                    <ul className="list">
                      <li className="d-flex justify-content-between">
                        <strong>Location:</strong>
                        <span>{Properti.lokasi.lokasitempat}</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Property Type:</strong>
                        <span>House</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Daya Listrik:</strong>
                        <span>{Properti.listrik} VA</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Jenis Sumber Air:</strong>
                        <span>{Properti.air}</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Sertifikat:</strong>
                        <span>{Properti.sertifikat}</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Luas Tanah:</strong>
                        <span>{Properti.area.luasTanah}m
                          <sup>2</sup>
                        </span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Luas Bangunan:</strong>
                        <span>{Properti.area.luasBangunan}m
                          <sup>2</sup>
                        </span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Beds:</strong>
                        <span>{Properti.kamarTidur}</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        <strong>Baths:</strong>
                        <span>{Properti.kamarMandi}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-7 section-md-t3">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="title-box-d">
                      <h3 className="title-d">Property Description</h3>
                    </div>
                  </div>
                </div>
                <div className="property-description">
                  <p className="description color-text-a">
                    {Properti.deskripsi}
                  </p>
                </div>
                <div className="row section-t3">
                  <div className="col-sm-12">
                    <div className="title-box-d">
                      <h3 className="title-d">Fasilitas</h3>
                    </div>
                  </div>
                </div>
                <div className="amenities-list color-text-a">
                  <ul className="list-a no-margin" style={{marginBlockEnd: '0em'}}>
                    {Properti.fasilitas.map((fasilitasSingle,index)=>(
                      <li>{fasilitasSingle} </li>
                    ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 offset-md-1">
            <ul className="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="pills-plans-tab" data-bs-toggle="pill" href="#pills-plans" role="tab" aria-controls="pills-plans" aria-selected="false">Floor Plans</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="pills-map-tab" data-bs-toggle="pill" href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">Ubication</a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane active" id="pills-plans" role="tabpanel" aria-labelledby="pills-plans-tab">
                <img src={Properti.fotodenah.urldownload} alt="" className="img-fluid"/>
              </div>
              <div className="tab-pane fade" id="pills-map" role="tabpanel" aria-labelledby="pills-map-tab">
              <MapContainer
                
                  center={[Properti.lokasi.latitude,Properti.lokasi.longitude]}
                  zoom={24}
                  style={{width: '100%', height: '50vh',position:'relative'}}
                  >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        position={[Properti.lokasi.latitude,Properti.lokasi.longitude]}
                      />
                </MapContainer>
              </div>
            </div>
          </div>
          <SimulasiKPR hargarumah={Properti.harga} />
          <div className="col-md-12">
            <div className="row section-t3">
              <div className="col-sm-12">
                <div className="title-box-d">
                  <h3 className="title-d">Contact Agent</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <img src={penjual.fotourl} alt="" className="img-fluid"/>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="property-agent">
                  <a href={'/agen/'+Properti.uid} className="link-one"><h4 className="title-agent">{penjual.nama}</h4></a>
                  <p className="color-text-a">
                    {penjual.deskripsi_pribadi}
                  </p>
                  <ul className="list-unstyled">
                    <li className="d-flex justify-content-between">
                      <strong>Phone:</strong>
                      <span className="color-text-a">{penjual.telepon}</span>
                    </li>
                    <li className="d-flex justify-content-between">
                      <strong>Email:</strong>
                      <span className="color-text-a">{penjual.email}</span>
                    </li>
                  </ul>
                  <div className="socials-a">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href={"https://facebook.com/"+penjual.facebook} className="link-one">
                            <i className="bi bi-facebook" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                        <a href={"https://tiktok.com/"+penjual.tiktok} className="link-one">
                              <i className="bi bi-tiktok" aria-hidden="true"></i>
                              </a>
                        </li>
                        <li className="list-inline-item">
                            <a href={"https://instagram.com/"+penjual.instagram} className="link-one">
                            <i className="bi bi-instagram" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href={"https://linkedin.com/"+penjual.linkedIn} className="link-one">
                            <i className="bi bi-linkedin" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="property-contact">
                  <form className="form-a">
                    <div className="row">
                      <div className="col-md-12 mb-1">
                        <div className="form-group">
                          <input type="text" className="form-control form-control-lg form-control-a" id="inputName" placeholder="Name *" required/>
                        </div>
                      </div>
                      <div className="col-md-12 mb-1">
                        <div className="form-group">
                          <input type="email" className="form-control form-control-lg form-control-a" id="inputEmail1" placeholder="Email *" required/>
                        </div>
                      </div>
                      <div className="col-md-12 mb-1">
                        <div className="form-group">
                          <textarea id="textMessage" className="form-control" placeholder="Comment *" name="message" cols="45" rows="8" required></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <button type="submit" className="btn btn-a">Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        </main>
      )
    }
</>
    );
}
const CariRumah = () => {
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(4);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(role == "Penjual"){
          const propertiesCollection = await getDocs(query(collection(db, "properti"), where("uid", "==", uid)));
          const propertiesData = propertiesCollection.docs.map((doc) => doc.data());
          setProperties(propertiesData);
        }
        else{
          const propertiesCollection = await getDocs(collection(db, "properti"));
          const propertiesData = propertiesCollection.docs.map((doc) => doc.data());
          setProperties(propertiesData);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <main id="main">
      <section className="intro-single">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div  >
                <h1 className="title-single">Cari Rumah Impianmu</h1>
              </div>
            </div>
            <div className="col-md-12 col-lg-3">
            <div className="container center" style={{marginTop:"10%",marginLeft:"50%"}}>
            { role != 'Pembeli'?
              (<a href="/Rumah/tambah" className='btn btn-warning right'>
              Tambahkan Rumah
               </a>):(<>
               </>)
            }
            </div>
            </div>
          </div>
        </div>
      </section>
      <section className="property-grid grid">
      <div className="container">
        <div className="row">
            <div className="col-sm-12">
              <div className="grid-option">
                <form>
                  <select className="custom-select">
                    <option selected>All</option>
                    <option value="1">New to Old</option>
                  </select>
                </form>
              </div>
            </div>
            { currentProperties.length > 0 &&
            currentProperties.map((property, index) => (
            <a href={'/Rumah/'+property.id} key={index}> 
              <div className="col-md-4">
                <div className="card-box-a card-shadow">
                  <div className="img-box-a">
                    <img src={property?.fotorumah?.[0]?.urldownload} alt="" style={{objectFit:'cover'}} className="img-a img-fluid"/>
                  </div>
                  <div className="card-overlay">
                    <div className="card-overlay-a-content">
                      <div className="card-header-a">
                        <h2 className="card-title-a">
                          <a href={'/Rumah/'+property.id}>{property.nama}</a>
                        </h2>
                      </div>
                      <div className="card-body-a">
                        <div className="price-box d-flex">
                          <span className="price-a">Rp {numeral(property.harga).format('0,0')}</span>
                        </div>
                        <a href={'/Rumah/'+property.id} className="link-a">Click here to view
                          <span className="bi bi-chevron-right"></span>
                        </a>
                      </div>
                      <div className="card-footer-a">
                        <ul className="card-info d-flex justify-content-around">
                          <li>
                            <h4 className="card-info-title">Area</h4>
                            <span>{property.area.luasTanah} m 
                              <sup>2</sup>
                            </span>
                          </li>
                          <li>
                            <h4 className="card-info-title">Beds</h4>
                            <span>{property.kamarTidur}</span>
                          </li>
                          <li>
                            <h4 className="card-info-title">Baths</h4>
                            <span>{property.kamarMandi}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </a>
            ))}
        </div>
        </div>
      </section>
      <div className="col-sm-12">
        <nav className="pagination-a">
          <ul className="pagination justify-content-end">
            <li className={`page-item ${currentPage === 1 && "disabled"}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="bi bi-chevron-left"></span>
              </button>
            </li>
            {Array.from({ length: Math.ceil(properties.length / propertiesPerPage) }, (_, i) => (
              <li
                key={i}
                className={`page-item ${currentPage === i + 1 && "active"}`}
              >
                <button className="page-link" onClick={() => paginate(i + 1)}>
                  {i + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${currentPage === Math.ceil(properties.length / propertiesPerPage) && "disabled"}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(properties.length / propertiesPerPage)}
              >
                <span className="bi bi-chevron-right"></span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </main>
  );
};
const EditRumah = () => {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const param = useParams();

  const [uid, setUid] = Akun.useState("uid", {default: "Pembeli"});
  
  const idRumah = param.idrumah;
  const tanggal = new Date();
  const [Properti, setProperti] = useState(
    {
      id:'',
      tanggalupdate: tanggal.toLocaleString(),
      nama: '',
      harga:'',
      deskripsi:'',
      listrik:'',
      air:'',
      sertifikat:'',
      uid:uid,
      lokasi:{
        lokasitempat:'',
        latitude:'',
        longitude:''
      },
      statusRumah:'',
      area : {
        luasTanah:'',
        luasBangunan:'',
        lebarDepan:''
      },
      kamarTidur:'',
      kamarMandi:'',
      fotorumah: [],
      fotodenah: {
        namafile:'',
        urldownload:''
      },
      fasilitas: []
  });
  const removeRumah = () =>{
    if (window.confirm("Delete the item?")) {
      Properti.fotorumah.map((file, index) => {
      handleRemovePhoto(file)})
      if(idRumah!="tambah"){
        const docRef = doc(db, "properti", idRumah);
        deleteDoc(docRef).then(() => {
          /* */
          navigate("/")
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
      }
      
    }
  } 
  // Usage
  const search = new GeoSearchControl({
    provider: new OpenStreetMapProvider({
      params: {
        addressdetails: 1, // include additional address detail parts
      },
      showMarker: false,
    }),
    showMarker: false,
  });
  const [geolokasi, setGeolokasi] = useState(search);
  const MyMap = () =>{
    const map = useMap();
    if(geolokasi != ""){
      map.addControl(geolokasi);
      setGeolokasi("");
    }

    if(Properti.lokasi.latitude == "" ){

      if(idRumah =='tambah'){
        map.locate().on("locationfound", function (e) {
          map.flyTo(e.latlng, map.getZoom());
        });
      }
    }
    else{
      map.flyTo(latLng(Properti.lokasi.latitude,Properti.lokasi.longitude), map.getZoom());
    }
      map.on('geosearch/showlocation', (e) => {
        const { location } = e;
        // Lakukan sesuatu dengan hasil pencarian
        /* */
        // map.flyTo(latLng(location.y,location.x), map.getZoom());
        // Set state atau lakukan tindakan lain sesuai kebutuhan aplikasi Anda.
        setProperti((databefore) => ({
          ...databefore,
          "lokasi": {
            ...databefore.lokasi,
            ["lokasitempat"]: String(location.label),
            ["latitude"]: String(location.y),
            ["longitude"]: String(location.x),
          }
        }));
      });
      map.on("click", (e) => {    
        map.flyTo(e.latlng, map.getZoom());
        if(Properti.lokasi.lokasitempat == ""){
          const provider = new OpenStreetMapProvider();
          provider.search({ query: e.latlng })
            .then((results) => {
              /* */
              if (results.length > 0) {
                setProperti((databefore)=>(
                  {...databefore,
                    "lokasi":{
                      ...databefore.lokasi,
                      ["lokasitempat"]:String(results[0].label),
                    }
                }));
                
              } else {
                /* */
              }
            })
            .catch((error) => {
              console.error('Error during reverse geocoding:', error);
            });
        }
        // Memperbarui state properti dengan lokasi baru
        setProperti((databefore)=>(
          {...databefore,
            "lokasi":{
              ...databefore.lokasi,
              ["latitude"]:String(e.latlng.lat),
              ["longitude"]:String(e.latlng.lng),
            }
        }));
      }
      );
  }

  const[fasilitasSingle,setFasilitasSingle] = useState("");
  const addFasilitas = () => {
  if (fasilitasSingle.trim() !== '') {
      setProperti((databefore)=>(
        {...databefore,
          "fasilitas":[
            ...databefore.fasilitas,
            fasilitasSingle,
          ]
      }));
      setFasilitasSingle('');
    }
  };



  const removeFasilitas = (elementToRemove) => {
    const newArray = Properti.fasilitas.filter((element) => element !== elementToRemove);
    setProperti((databefore)=>(
      {...databefore,
        "fasilitas":newArray,
    }));
  };
  const handleRemovePhoto = async (index) => {
    const desertRef = ref(storage, index.namafile);
    // Delete the file
    deleteObject(desertRef).then(() => {
      const newArray = Properti.fotorumah.filter((element) => element !== index);
      setProperti((databefore)=>(
        {...databefore,
          "fotorumah":newArray,
      }));
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  };
  const handleRemoveDenah = async (index) => {
    const desertRef = ref(storage, index.namafile);
    // Delete the file
    deleteObject(desertRef).then(() => {
      setProperti((databefore)=>(
        {...databefore,
          "fotodenah":{
            namafile:'',
            urldownload:''
          }
      }));
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  };
  const[angkas,setAngka] = useState("");
  const formatAngka = (setReal, setDisplay) => (event) => {
    let angka = event.target.value;
    angka = angka.slice(0, 17);
    angka = numeral(angka).format('0,0');
    setDisplay(angka);
  }
  useEffect(() => {
      if(idRumah!=='tambah'){
        try {
          const docRef = doc(db, "properti", idRumah);
          const docSnap = getDoc(docRef).then(async (doc)=>{
            if (doc.exists()) {
              const data = doc.data();
              setProperti((prevData) => ({
                ...prevData,
                id:data.id||'',
                tanggalupdate:data.tanggalupdate||'',
                nama: data.nama||'',
                listrik:data.listrik||'',
                air:data.air||'',
                sertifikat:data.sertifikat||'',
                harga:data.harga||'',
                deskripsi:data.deskripsi||'',
                lokasi:data.lokasi||{
                  lokasitempat:'',
                  latitude:'',
                  longitude:''
                },
                statusRumah:data.statusRumah||'',
                area :data.area|| {
                  luasTanah:'',
                  luasBangunan:'',
                  lebarDepan:''
                },
                kamarTidur:data.kamarTidur||'',
                kamarMandi:data.kamarMandi||'',
                fotorumah: data.fotorumah||[],
                fotodenah: data.fotodenah||'',
                fasilitas: data.fasilitas||[]
              }));
              setAngka(numeral(data.harga.slice(0, 15)).format('0,0'));
            } else {
              /* */
            }
          })
        } catch (error) {
          console.error('Error fetching properti data:', error);
        }
      }
      else{
        var randomString = require('random-string');
        setProperti((prevData) => ({...prevData,'id':randomString({length: 20})}));
      }
    }, [idRumah]);

  const metadata = {
    contentType: 'image/jpeg'
  };

  const handleFileSelect = (file, kategori) => {
    try {
      /* */
      setLoading(true);
      /* */
      const imageRef = ref(storage,String('properti/'+Properti.id+'/'+file.name));
      uploadBytes(imageRef, file).then(async (snapshot) => {
        /* */
        getDownloadURL(imageRef).then((url) => {
          if(kategori == "foto"){
            setProperti((prevData) => ({
              ...prevData,
              'fotorumah': [...prevData.fotorumah,
                {
                  'namafile':String('properti/'+Properti.id+'/'+file.name),
                  'urldownload':String(url),
                }
              ]
            }));
          }
          else if(kategori == "denah"){
            setProperti((prevData) => ({
              ...prevData,
              'fotodenah': {
                  'namafile':String('properti/'+Properti.id+'/'+file.name),
                  'urldownload':String(url),
                }
            }));
          }
        })
      });
    } catch (error) {
      console.error('Error uploading image to Firebase Storage:', error);
    }
    finally{
      setLoading(false);
    }
  };
  const handlemultiplefile = (files) => {
    try {
      // Convert files to an array
      const filesArray = Array.from(files);
  
      // Use forEach on the array
      filesArray.forEach((file) => {
        handleFileSelect(file,"foto");
      });

    } catch (error) {
      console.error('Error handling multiple files:', error);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm("apakah data sudah benar?")) {
      try {
        if (idRumah != 'tambah') {
          const Blogref = doc(db, 'properti', Properti.id);
          await setDoc(Blogref, Properti);
        } else {
          const Blogref = doc(db, 'properti', Properti.id);
          await setDoc(Blogref, Properti);
        }
        /* */
      } catch (error) {
        console.error('Error saving data:', error);
      } finally {
        navigate("/Rumah/",{replace : true});
      }
    }
  };
  
  return(
    <main id="main">
      <section className="news-single nav-arrow-b" style={{ marginTop: "10%" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div  >
                <h1 className="title-single">Rumah Editor</h1>
              </div>
              <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">Nama Produk</label>
                  <input type="judul" className="form-control" value={Properti.nama} id="exampleFormControlInput1" required onChange={e => {
                    setProperti((prevData) => ({...prevData,'nama':e.target.value}));
                    /* */
              }}/>
                </div>
                
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">Harga</label>
                  <input type="judul" className="form-control" value={angkas} id="exampleFormControlInput1" required onInput={e => {
                    let angkadisplay = e.target.value.slice(0, 18);
                    setAngka(numeral(angkadisplay).format('0,0'));
                    let angka = angkadisplay.replace(/\D/g, '');
                    setProperti((prevData) => ({...prevData,'harga':angka}));
                    /* */
                  }}
               />
                </div>
                <div className="mb-3">
                <label for="exampleFormControlTextarea1" className="form-label">Deskripsi</label>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={Properti.deskripsi} required onChange={e => {
                    setProperti((prevData) => ({...prevData,'deskripsi':e.target.value}));
                    /* */
              }} >
                {Properti.deskripsi}
              </textarea>
                </div>
                
                <div className="form-group">
                <MapContainer
                  center={[-6.424438610427816,106.74334179629714]}
                  zoom={24}
                  style={{width: '100%', height: '50vh',position:'relative'}}
                  >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        position={[Properti.lokasi.latitude,Properti.lokasi.longitude]}
                      />
                  <MyMap />
                </MapContainer>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">Lokasi Detail</label>
                  <input type="text" className="form-control" value={Properti.lokasi.lokasitempat} id="exampleFormControlInput1" required onChange={e => {
                          setProperti((databefore)=>(
                            {...databefore,
                              "lokasi":{
                                ...databefore.lokasi,
                                ["lokasitempat"]:String(e.target.value),
                              }
                          }));
                    /* */
              }}/>
                </div>
              </div>
                <div className="mb-3">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="mb-3">
                    <label for="exampleFormControlInput1" className="form-label">Luas Tanah</label>
                    <input type="number" className="form-control" value={Properti.area.luasTanah} id="exampleFormControlInput1" required onChange={e => {
                      setProperti((databefore)=>(
                        {...databefore,
                          "area":{
                            ...databefore.area,
                            ["luasTanah"]:String(e.target.value),
                          }
                      }));
                      /* */
                   }}/>
                   </div>
                   <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">Lebar Depan</label>
                  <input type="number" className="form-control" value={Properti.area.lebarDepan} id="exampleFormControlInput1" required onChange={e => {
                  setProperti((databefore)=>(
                    {...databefore,
                      "area":{
                        ...databefore.area,
                        ["lebarDepan"]:String(e.target.value),
                      }
                  }));
                    /* */
              }}/>
                </div>

                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">Luas Bangunan</label>
                  <input type="number" className="form-control" value={Properti.area.luasBangunan} id="exampleFormControlInput1" required onChange={e => {
                    setProperti((databefore)=>(
                      {...databefore,
                        "area":{
                          ...databefore.area,
                          ["luasBangunan"]:String(e.target.value),
                        }
                    }));
                    /* */
              }}/>
                </div>
                </div>
                </div>         
                <div className="mb-3">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">Kamar Tidur</label>
                        <input type="number" className="form-control" value={Properti.kamarTidur} id="exampleFormControlInput1" required onChange={e => {
                          setProperti((prevData) => ({...prevData,'kamarTidur':e.target.value}));
                          /* */
                    }}/>
                      </div>
                      <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">Kamar Mandi</label>
                        <input type="number" className="form-control" value={Properti.kamarMandi} id="exampleFormControlInput1" required onChange={e => {
                          setProperti((prevData) => ({...prevData,'kamarMandi':e.target.value}));
                          /* */
                    }}/>
                      </div>
                  </div>
                </div>
                <select className="form-select form-select-md mb-3" value={Properti.listrik} aria-label=".form-select-md example" 
                onChange={e => {
                    setProperti((prevData) => ({...prevData,'listrik':e.target.value}));
                    /* */
              }} required>
                  <option selected> silahkan pilih Daya Listrik </option>
                  <option value="900">900 VA</option>
                  <option value="1300">1300 VA</option>
                  <option value="2200">2200 VA</option>
                  <option value="3500">3500 VA</option>
                  <option value="5500">5500 VA</option>
                  <option value="6600">6600 VA</option>
                  <option value="diatas 6600">Diatas 6600 VA</option>
                </select>
                <select className="form-select form-select-md mb-3" value={Properti.air} aria-label=".form-select-md example" 
                onChange={e => {
                    setProperti((prevData) => ({...prevData,'air':e.target.value}));
                    /* */
              }} required>
                  <option selected> Silahkan Pilih Jenis Sumber Air </option>
                  <option value="PDAM">PDAM</option>
                  <option value="Sumur Bor">Sumur Bor</option>
                  <option value="Sumur Pompa">Sumur Pompa</option>
                  <option value="Sumur Resapan">Sumur Resapan</option>
                </select>
                <select className="form-select form-select-md mb-3" value={Properti.sertifikat} aria-label=".form-select-md example" 
                onChange={e => {
                    setProperti((prevData) => ({...prevData,'sertifikat':e.target.value}));
                    /* */
              }} required>
                  <option selected> Silahkan Pilih Jenis Sertifikat </option>
                  <option value="SHM">SHM</option>
                  <option value="HGB">HGB</option>
                  <option value="IMB">IMB</option>
                  <option value="PPJB">PPJB</option>
                  <option value="HGU">HGU</option>
                </select>
                <div className="mb-3" >
                  <label for="exampleFormControlInput1" className="form-label">Fasilitas</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                      type="text"
                      className="form-control"
                      value={fasilitasSingle}
                      onChange={(e) => setFasilitasSingle(e.target.value)}
                    />
                    <input type='button' className="btn btn-warning" onClick={(e)=> {
                      e.preventDefault();
                      addFasilitas();}} value="tambah fasilitas"/>
                  </div>
                  </div>
                  <ul>
                    {Properti.fasilitas.map((element, index) => (
                      <li key={index}>
                        {element}{' '}
                        <input type='button' className="btn btn-danger" onClick={() => removeFasilitas(element)} value="hapus fasilitas"/>
                      </li>
                    ))}
                  </ul>
                <div className="input-group mb-3">
                  <input type="file" className="form-control" id="inputGroupFile02" multiple accept="image/*" onChange={e => handlemultiplefile(e.target.files)} />
                  <label className="input-group-text"  for="inputGroupFile02">Upload images</label>
                </div>
                {Properti.fotorumah.length > 0 &&(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="row">
                  {Properti.fotorumah.map((file, index) => (
                    <div className="col md-3-sm-6" key={index}>
                      <img
                        src={file.urldownload}
                        alt={`Image ${index}`}
                        style={{ width: '100px', height: '100px', margin: '5px' }}
                      />
                      <button  
                      className="btn btn-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemovePhoto(file);}}>Hapus</button>
                    </div>
                  ))}
                  </div>
                </div>
                )}
                <div className="mb-3">
                <div className="input-group mb-3">
                  <input type="file" className="form-control" id="inputGroupFile02" accept="image/*" onChange={e => handleFileSelect(e.target.files[0],"denah")} />
                  <label className="input-group-text"  for="inputGroupFile02">Upload Denah</label>
                </div>
                {Properti.fotodenah.urldownload!='' &&(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="row">
                    <div className="col-3">
                      <img
                        src={Properti.fotodenah.urldownload}
                        alt={`Image denah`}
                        style={{ width: '100px', height: '100px', margin: '5px' }}
                      />
                      <button
                      className="btn btn-danger"
                       onClick={(e) => {
                        e.preventDefault();
                        handleRemoveDenah(Properti.fotodenah);}}>Hapus</button>
                    </div>
                  </div>
                </div>
                )}
                </div>
                <button disabled={loading} className="btn btn-warning" type="submit">Save</button>
                <button className="btn btn-danger" onClick={(e) => {
                  e.preventDefault();
                  removeRumah();}}>Delete</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>

  );
};
export {CariRumah, PropertiRumah, EditRumah};
