import React from "react";
import banerkontak from "../../assets/img/bannerkontak.jpg";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';
const KontakKami = () => {
    return(
    <main id='main'>
        <section className="intro-single">
        <div className="container">
        <Swiper
                style={{top:'10%',height: 'auto'}}
                // install Swiper modules
                modules={[Pagination]}
                slidesPerView={1}
                spaceBetween={0}
                autoplay={true}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper"
                >
                <SwiperSlide><img src={banerkontak} style={{objectFit:'contain',maxWidth: '100%',height: 'auto'}} /></SwiperSlide>
        </Swiper>
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div  >
                <h1 className="title-single">Kontak Kami</h1>
                <span className="color-text-a"></span>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <nav aria-label="breadcrumb" className="breadcrumb-box d-flex justify-content-lg-end">
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-7">
                <h2 className="icon-title">Formulir</h2>
              <p>Isi formulir di bawah ini. Kamu langsung terhubung ke Admin Gogateproperti.</p>
                  <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <input type="text" name="name" className="form-control form-control-lg form-control-a" placeholder="Your Name" required/>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <input name="email" type="email" className="form-control form-control-lg form-control-a" placeholder="Your Email" required/>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <input type="text" name="subject" className="form-control form-control-lg form-control-a" placeholder="Subject" required/>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea name="message" className="form-control" cols="45" rows="8" placeholder="Message" required></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 my-3">
                        <div className="mb-3">
                          <div className="loading">Loading</div>
                          <div className="error-message"></div>
                          <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                      </div>
  
                      <div className="col-md-12 text-center">
                        <button type="submit" className="btn btn-a">Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-5 section-md-t3">
                  <div className="icon-box section-b2">
                    <div className="icon-box-icon">
                      <span className="bi bi-envelope"></span>
                    </div>
                    <div className="icon-box-content table-cell">
                      <div className="icon-box-title">
                        <h4 className="icon-title">Hubungi Kami</h4>
                      </div>
                      <div className="icon-box-content">
                        <p className="mb-1">Email:  
                          <a href='mailto:agencyrasofficial@gmail.com' style={{textDecoration:'none'}}><span className="color-a"> agencyrasofficial@gmail.com</span></a>
                        </p>
                        <p className="mb-1">Phone:  
                        <a href="https://wa.me/6285715474054" style={{textDecoration:'none'}}><span className="color-a"> +62 858-1149-7662</span></a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icon-box section-b2">
                    <div className="icon-box-icon">
                      <span className="bi bi-geo-alt"></span>
                    </div>
                    <div className="icon-box-content table-cell">
                      <div className="icon-box-title">
                        <h4 className="icon-title">Temukan Kami</h4>
                      </div>
                      <div className="icon-box-content">
                        <p className="mb-1">
                          Jl Kasiba Lasiba, Kampung Kandang RT-03 RW-02 
                          <br/>Kelurahan Duren Seribu, Kecamatan Bojongsari
                          <br/>Depok, Jawa Barat.
                          <br/>16516
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icon-box">
                    <div className="icon-box-icon">
                      <span className="bi bi-share"></span>
                    </div>
                    <div className="icon-box-content table-cell">
                      <div className="icon-box-title">
                        <h4 className="icon-title">Jejaring Social</h4>
                      </div>
                      <div className="icon-box-content">
                        <div className="socials-footer">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a href="https://www.facebook.com/profile.php?id=61551047473097" className="link-one">
                                <i className="bi bi-facebook" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="https://www.tiktok.com/@golden.official.id" className="link-one">
                                <i className="bi bi-tiktok" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="https://www.instagram.com/gogatepro/" className="link-one">
                                <i className="bi bi-instagram" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="https://www.youtube.com/@goldengateproperti" className="link-one">
                                <i className="bi bi-youtube" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12" style={{marginTop:'10%'}}>
              <div className="contact-map box">
                <div id="map" className="contact-map">
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15859.042270846707!2d106.743492!3d-6.4248011!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e9bcf17ab8d7%3A0x3ac39200aecf1443!2sPT.%20Rampak%20Abhipraya%20Sejahtera!5e0!3m2!1sid!2sid!4v1707431117137!5m2!1sid!2sid"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0, width: '100%', height: '450px' }}  // Perubahan pada baris ini
                allowFullScreen
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-about" style={{margin:'10%'}}>
          <div className="container">
          <div class="card text-center">
              <div class="card-body">
                <h5 class="card-title">Siap Miliki Hunian Idaman</h5>
                <p class="card-text">Dapatkan Informasi Lengkap dan penawaran menarik seputar hunian idaman yang Anda inginkan</p>
                <a href="/Rumah" class="btn btn-warning">Cari rumah impianmu</a>
              </div>
          </div>
          </div>
        </section>
    </main>
    );
}
export default KontakKami;