import React, { useState, useEffect} from "react";// Sesuaikan dengan library yang Anda gunakan
import { db, storage } from "../../firebase";
import { getDoc,getDocs, doc, collection, setDoc,deleteDoc, query, where, updateDoc  } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Akun } from "../../state/akun";
import { ref, uploadBytes,getDownloadURL, deleteObject } from "firebase/storage";
import numeral from 'numeral';

const EditProfilAdmin = () => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
  const [Admin, setAdmin] = useState(
      {
          nama:'',
          email:'',
          telepon:'',
          fotourl:'',
          foto:'',
          
      }
  );
  useEffect(()=>{
    const lengkapiakun = () =>{
      try {
          const docRef = doc(db, "Users", uid);
          const docSnap = getDoc(docRef).then(async (doc)=>{
            if (doc.exists()) {
              const data = doc.data();
              setAdmin((prevData) => ({
                ...prevData,
                nama:data.nama||'',
                telepon:data.telepon||'',
                fotourl:data.fotourl||'',
                foto:data.foto||'',
              }));
            } else {
              /* */
            }
          })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }
    lengkapiakun();
  }, []);
  const handleFileSelect = (file) => {
    try {
      /* */
      setLoading(true);
      setAdmin((prevData) => ({
        ...prevData,
        'foto':String('user/'+uid+'/'+file.name)
      }));
      /* */
      const imageRef = ref(storage,String('user/'+uid+'/'+file.name));
      uploadBytes(imageRef, file).then(async (snapshot) => {
        /* */
        getDownloadURL(imageRef).then((url) => {
          setAdmin((prevData) => ({
            ...prevData,
            'fotourl':String(url),
          }));
        })
        
      });

    } catch (error) {
      console.error('Error uploading image to Firebase Storage:', error);
    }
    finally{
      setLoading(false);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
        const Blogref = doc(db, 'Users', uid);
        await updateDoc(Blogref, Admin);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      navigate("/",{replace : true});
    }
  };
    return (
        <main id="main">
          <section className="news-single nav-arrow-b" style={{ marginTop: "10%" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <div className="title-single-box">
                    <h1 className="title-single">Artikel Editor</h1>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">judul dari artikel</label>
                      <input type="judul" className="form-control" value={Admin.title} id="exampleFormControlInput1" required onChange={e => {
                        setAdmin((prevData) => ({...prevData,'title':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">Author</label>
                      <input type="Author" className="form-control" value={Admin.author} id="exampleFormControlInput1" required onChange={e => {
                        setAdmin((prevData) => ({...prevData,'author':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <select className="form-select form-select-md mb-3" value={Admin.category} aria-label=".form-select-md example" 
                    onChange={e => {
                        setAdmin((prevData) => ({...prevData,'category':e.target.value}));
                        /* */
                  }} required>
                      <option selected> silahkan pilih kategori </option>
                      <option value="Panduan">Panduan Properti</option>
                      <option value="Berita">Berita</option>
                      <option value="Tips and Trik">Tips and Trik</option>
                      <option value="Lifestyle">Lifestyle</option>
                      <option value="Pembiayaan">Pembiayaan</option>
                    </select>
                    <div className="input-group mb-3">
                      <input type="file" className="form-control" id="inputGroupFile02" accept="image/*" onChange={e => handleFileSelect(e.target.files[0])} />
                      <label className="input-group-text"  for="inputGroupFile02">Upload images</label>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlTextarea1" className="form-label">Isi Artikel</label>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={Admin.content} required onChange={e => {
                        setAdmin((prevData) => ({...prevData,'content':e.target.value}));
                        /* */
                  }} ></textarea>
                    </div>
                    <button disabled={loading} className="btn btn-warning" type="submit">Save</button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
      );
}
const EditProfilPenjual = () => {
  const navigate = useNavigate();
  const parameter = useParams();
  const [loading,setLoading] = useState(false);
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
  const [Penjual, setPenjual] = useState(
        {
            nama:'',
            email:'',
            telepon:'',
            instagram:'',
            facebook:'',
            tiktok:'',
            linkedIn:'',
            fotourl:'',
            foto:'',
            deskripsi_pribadi:'',
            youtube:'',
            x:'',
        }
    )
    useEffect(() => {
      const lengkapiakun = () =>{
          try {
              if(role == "Penjual"){
                const docRef = doc(db, "Users", uid);
                const docSnap = getDoc(docRef).then(async (doc)=>{
                  if (doc.exists()) {
                    const data = doc.data();
                    setPenjual((prevData) => ({
                      ...prevData,
                      nama:data.nama||'',
                      telepon:data.telepon||'',
                      instagram:data.instagram||'',
                      linkedIn:data.linkedIn||'',
                      facebook:data.facebook||'',
                      fotourl:data.fotourl||'',
                      deskripsi_pribadi:data.deskripsi_pribadi||'',
                      youtube:data.youtube||'',
                      x:data.x||'',
                      foto:data.foto||'',
                    }));
                  } else {
                    /* */
                  }
                })
              }
              else if(role == "Admin"){
                const docRef = doc(db, "Users", parameter.uidParam);
                const docSnap = getDoc(docRef).then(async (doc)=>{
                  if (doc.exists()) {
                    const data = doc.data();
                    setPenjual((prevData) => ({
                      ...prevData,
                      nama:data.nama||'',
                      telepon:data.telepon||'',
                      instagram:data.instagram||'',
                      linkedIn:data.linkedIn||'',
                      facebook:data.facebook||'',
                      fotourl:data.fotourl||'',
                      deskripsi_pribadi:data.deskripsi_pribadi||'',
                      youtube:data.youtube||'',
                      x:data.x||'',
                      foto:data.foto||'',
                    }));
                  } else {
                    /* */
                  }
                })
              }

            } catch (error) {
              console.error('Error fetching data:', error);
            }
      }
      lengkapiakun();
    }, []);
    const handleFileSelect = (file) => {
      try {
        /* */
        setLoading(true);
        setPenjual((prevData) => ({
          ...prevData,
          'foto':String('user/'+uid+'/'+file.name)
        }));
        /* */
        const imageRef = ref(storage,String('user/'+uid+'/'+file.name));
        uploadBytes(imageRef, file).then(async (snapshot) => {
          /* */
          getDownloadURL(imageRef).then((url) => {
            setPenjual((prevData) => ({
              ...prevData,
              'fotourl':String(url),
            }));
          })
          
        });
  
      } catch (error) {
        console.error('Error uploading image to Firebase Storage:', error);
      }
      finally{
        setLoading(false);
      }
    };
    const handleFormSubmit = async (e) => {
      e.preventDefault();
      try {
          if(role == "Penjual"){
            const Blogref = doc(db, 'Users', uid);
            await updateDoc(Blogref, Penjual);
          }
          else if(role == "Admin"){
            const Blogref = doc(db, 'Users', parameter.uidParam);
            await updateDoc(Blogref, Penjual);
          }

      } catch (error) {
        console.error('Error saving data:', error);
      } finally {
        navigate("/",{replace : true});
      }
    };
    return (
        <main id="main">
          <section className="news-single nav-arrow-b" style={{ marginTop: "10%" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <div className="title-single-box">
                    <h1 className="title-single">Penjual Editor</h1>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">Nama</label>
                      <input type="text" className="form-control" value={Penjual.nama} id="exampleFormControlInput1" required onChange={e => {
                        setPenjual((prevData) => ({...prevData,'nama':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">Nomor Telepon</label>
                      <input type="tel" className="form-control" value={Penjual.telepon} id="exampleFormControlInput1" required onChange={e => {
                        setPenjual((prevData) => ({...prevData,'telepon':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <div className="input-group mb-3">
                      <input type="file" className="form-control" id="inputGroupFile02" accept="image/*" onChange={e => handleFileSelect(e.target.files[0])} />
                      <label className="input-group-text"  for="inputGroupFile02">Upload Foto anda</label>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlTextarea1" className="form-label">deskripsi tentang anda</label>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={Penjual.deskripsi_pribadi} required onChange={e => {
                        setPenjual((prevData) => ({...prevData,'deskripsi_pribadi':e.target.value}));
                        /* */
                  }} ></textarea>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">facebook</label>
                      <input type="text" className="form-control" value={Penjual.facebook} id="exampleFormControlInput1" onChange={e => {
                        setPenjual((prevData) => ({...prevData,'facebook':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">twitter</label>
                      <input type="text" className="form-control" value={Penjual.x} id="exampleFormControlInput1" onChange={e => {
                        setPenjual((prevData) => ({...prevData,'x':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">instagram</label>
                      <input type="text" className="form-control" value={Penjual.instagram} id="exampleFormControlInput1" onChange={e => {
                        setPenjual((prevData) => ({...prevData,'instagram':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">tiktok</label>
                      <input type="text" className="form-control" value={Penjual.tiktok} id="exampleFormControlInput1" onChange={e => {
                        setPenjual((prevData) => ({...prevData,'tiktok':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleFormControlInput1" className="form-label">linkedIn</label>
                      <input type="text" className="form-control" value={Penjual.linkedin} id="exampleFormControlInput1" onChange={e => {
                        setPenjual((prevData) => ({...prevData,'linkedin':e.target.value}));
                        /* */
                  }}/>
                    </div>
                    <button disabled={loading} className="btn btn-warning" type="submit">Save</button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
      );
}
export {EditProfilAdmin, EditProfilPenjual};