import numeral from 'numeral';
import React, { useState, useEffect } from 'react';
import bannerKPR from "../../assets/img/BannerKPR.jpg";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const SimulasiKPR = ( hargarumah = 0 ) => {
  const initialHargaRumah = hargarumah;
  const [pinjamanDisplay, setPinjamanDisplay] = useState('');
  const [uangMukaDisplay, setUangMukaDisplay] = useState('');
  const [jumlahPinjaman, setJumlahPinjaman] = useState(0);
  const [jangkaWaktu, setJangkaWaktu] = useState('');
  const [uangMuka, setDP] = useState('');
  const [stylehead, setstylehead] = useState({});
  const [pembayaranBulanan, setPembayaranBulanan] = useState(null);
  const [selectedPromo, setSelectedPromo] = useState('promo1'); // default to promo1
  const [showModal, setShowModal] = useState(false);
  const formatAngka = (setReal, setDisplay) => (event) => {
    let angka = event.target.value;
    angka = angka.replace(/\D/g, ''); // Hapus semua karakter non-angka// Batasi hingga 12 digit
    setReal(Number(angka));
    angka = angka.slice(0, 15);
    angka = numeral(angka).format('0,0');
    setDisplay(angka);
  }
  useEffect(() => {
    if(initialHargaRumah == 0){
      setstylehead({
        paddingTop:'5rem'
      })
    }
    else{
      setPinjamanDisplay(initialHargaRumah.hargarumah.toString());
      setJumlahPinjaman(initialHargaRumah.hargarumah);
    }
  }, [initialHargaRumah]);
  const hitungPembayaran = (e) => {
    e.preventDefault();

    const originalPokokPinjaman = parseFloat(jumlahPinjaman) - parseFloat(uangMuka);
    let pokokPinjaman = originalPokokPinjaman;
    let jumlahPembayaran = parseFloat(jangkaWaktu);
  
    let bungaPromo;

    // Menentukan suku bunga berdasarkan promo yang dipilih
    if (selectedPromo === 'promo1') {
      // Promo 1 tahun
      bungaPromo = [3.99, 5.49, 6.99, 8.49, 13.25];
    } else {
      // Promo 3 tahun
      bungaPromo = [4.47, 4.47, 4.47, 6.97, 9.47, 11.97, 13.25];
    }

    // Melakukan perhitungan pembayaran KPR berdasarkan suku bunga promo
    const pembayaranPerPeriode = [];
    let pembayaranDisplay = "";
    if(jangkaWaktu < bungaPromo.length){
      for (let i = 0; i < jangkaWaktu; i++) {
        const sukuBungaBulanan = bungaPromo[i] / 100 / 12;
    
        // Menghitung pembayaran bulanan berdasarkan rumus
  
        const pembayaranBulanan =
          (pokokPinjaman * sukuBungaBulanan) /
          (1 - Math.pow(1 + sukuBungaBulanan, -(jumlahPembayaran*12)));
        
          
        pembayaranDisplay = numeral(pembayaranBulanan.toFixed(2)).format('0,0');
        // Push total pembayaran bulanan ke array
        pembayaranPerPeriode.push(pembayaranDisplay);
    
        // Kurangi pokokPinjaman setelah pembayaran bulanan
        pokokPinjaman -= (pokokPinjaman / (jumlahPembayaran*12));
      }
    }
    else{
      for (let i = 0; i < bungaPromo.length; i++) {
        const sukuBungaBulanan = bungaPromo[i] / 100 / 12;
    
        // Menghitung pembayaran bulanan berdasarkan rumus
  
        const pembayaranBulanan =
          (pokokPinjaman * sukuBungaBulanan) /
          (1 - Math.pow(1 + sukuBungaBulanan, -(jumlahPembayaran*12)));
        
          
        pembayaranDisplay = numeral(pembayaranBulanan.toFixed(2)).format('0,0');
        // Push total pembayaran bulanan ke array
        pembayaranPerPeriode.push(pembayaranDisplay);
    
        // Kurangi pokokPinjaman setelah pembayaran bulanan
        pokokPinjaman -= (pokokPinjaman / (jumlahPembayaran*12));
      }
    }

    setPembayaranBulanan(pembayaranPerPeriode.map((pembayaran) => pembayaran));
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
        <div className={`container ${showModal ? 'blurred' : ''}`} style={stylehead}>
            <div className="row section-t3">
            {initialHargaRumah == 0 &&(
                  <Swiper
                  style={{top:'0%',bottom:'10%',height: 'auto'}}
                  // install Swiper modules
                  modules={[Pagination]}
                  slidesPerView={1}
                  spaceBetween={0}
                  autoplay={true}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  className="mySwiper"
                  >
                  <SwiperSlide><img src={bannerKPR} style={{objectFit:'contain',maxWidth: '100%',height: 'auto'}} /></SwiperSlide>
                  </Swiper>
                  )}

              <div className="col-sm-12">
                <div>
                  <h3 className="title-single">Simulasi KPR</h3>
                </div>
              </div>
            </div>
          <div className='simulasi-form'>
            <form onSubmit={hitungPembayaran}>
              <div className='mb-3'>
                <label className='form-label'>Harga Rumah:</label>
                <input
                  className='form-control'
                  type="text"
                  value={pinjamanDisplay}
                  onInput={formatAngka(setJumlahPinjaman, setPinjamanDisplay)}
                  required
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Uang Muka/DP:</label>
                <input
                  className='form-control'
                  type="text"
                  value={uangMukaDisplay}
                  onInput={formatAngka(setDP, setUangMukaDisplay)}
                  required
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Jangka Waktu (Tahun):</label>
                <input
                  className='form-control'
                  type="number"
                  value={jangkaWaktu}
                  onChange={(e) => setJangkaWaktu(e.target.value)}
                  required
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Margin:</label>
                <select
                  className='form-select'
                  value={selectedPromo}
                  onChange={(e) => setSelectedPromo(e.target.value)}
                >
                  <option value="promo1">Margin 3,99%</option>
                  <option value="promo3">Margin 4,47%</option>
                </select>
              </div>

              <button className='btn btn-warning' type="submit">
                Hitung
              </button>
            </form>
          </div>
        </div>
    {showModal && (
          <div className='modal-wrapper'>
            <div className='modal' style={{ display: 'block' }}>
              <section className='modal-content'>
                  <span className='close' onClick={closeModal}>&times;</span>
                  <br/>
                  <h2 className='text-center'>Pembayaran Bulanan</h2>
                  <br />
                  <ul className='list-unstyled'>
                    {pembayaranBulanan &&
                      pembayaranBulanan.map((pembayaran, index) => (
                        <li key={index} className='mb-2 text-center'>
                          Tahun Ke {index + 1}: Rp {pembayaran} per bulan
                        </li>
                      ))}
                  </ul>
              </section>
            </div>
          </div>
          )}
    </>
  );
};



export default SimulasiKPR;
