import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from './searchbar';
import logowebsite from '../../assets/img/logowebsite.png';

const Navbar = (role) => {
  const [isBoxCollapseOpen, setIsBoxCollapseOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [ariaControlsValue, setAriaControlsValue] = useState(false);
  const navigate = useNavigate();

  const LogoutApp = () => {
    let konfirmasi = window.confirm("apakah anda yakin ingin keluar?")
    if (konfirmasi) {
      navigate("/logout");
    }
  }
  useEffect(() => {
    if (role.role === "Penjual") {
      setMenuItems([
        { label: 'Home', link: '/' },
        { label: 'Rumah', link: '/Rumah' },
        { label: 'Highlights', link: '/highlights' },
      ]);
    } else if (role.role === "Admin") {
      setMenuItems([
        { label: 'Home', link: '/' },
        { label: 'User', link: '/user' },
        { label: 'Rumah', link: '/Rumah' },
        { label: 'Blog', link: '/Blog' },
        { label: 'Testimoni', link: '/Testimoni' },
      ]);
    }
    else{
      setMenuItems([
        { label: 'Home', link: '/' },
        { label: 'Tentang Kami', link: '/tentangkami' },
        { label: 'Rumah', link: '/Rumah' },
        { label: 'Blog', link: '/Blog' },
        { label: 'Simulasi KPR', link: '/SimulasiKPR' },
        { label: 'Hubungi Kami', link: '/kontak' }
      ])
    }
  }, []);

  const handleToggleClick = () => {
    setIsBoxCollapseOpen(!isBoxCollapseOpen);
  };

  const handleCollapseClose = () => {
    setIsBoxCollapseOpen(false);
  };
  const navbarRef = useRef();
  const checkAriaControls = () => {
      setAriaControlsValue(!ariaControlsValue);
  };
  return (
    <>
      <SearchBar isBoxCollapseOpen={isBoxCollapseOpen} onClose={handleCollapseClose} />
      <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
        <div className="container">
          <button ref={navbarRef} className="navbar-toggler collapsed" onClick={checkAriaControls} type="button" data-bs-toggle="collapse" data-bs-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <a className="navbar-brand text-brand" href="/"><img src={logowebsite} alt="Logo" style={{ height: '50px' }} /></a>

          <div className="navbar-collapse collapse justify-content-center" id="navbarDefault">
            <ul className="navbar-nav">
              {menuItems.map((item, index) => (
                <li className="nav-item" key={index}>
                  <Link to={item.link} className="nav-link">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {role?.role == "Pembeli" ?(
          <>
          <a href='/login' className={`btn btn-b-n navbar-toggle-box ${isBoxCollapseOpen ? 'navbar-toggle-box-collapse' : ''}`} rel="noopener noreferrer">
            <i className="bi bi-person" style={{ 'WebkitTextStroke': '1px' }}></i>
          </a>
          <button type="button" className={`btn btn-b-n navbar-toggle-box ${isBoxCollapseOpen ? 'navbar-toggle-box-collapse' : ''}`}
            onClick={handleToggleClick}>
            <i className="bi bi-search" style={{ 'WebkitTextStroke': '1px' }}></i>
          </button>
          </>
          ):
          role?.role == "Penjual" ?(
            <div className="dropdown">
            <button className={`btn ${isBoxCollapseOpen ? 'navbar-toggle-box-collapse' : ''}`} rel="noopener noreferrer" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="true">
              <i className="bi bi-box-arrow-right" style={{ 'WebkitTextStroke': '1px' }}></i>
            </button>
            {ariaControlsValue ?(
            <ul className='dropdown-menu dropdown-menu-start'>
              <li><a className="dropdown-item" href="/EditProfile/">Edit Profil</a></li>
              <li><a className="dropdown-item" href="https://wa.me/6285811497662">Daftar Premium</a></li>
              <li><button className="dropdown-item" onClick={LogoutApp}>Logout</button></li>
            </ul>
            ):(
            <ul className='dropdown-menu dropdown-menu-end'>
              <li><a className="dropdown-item" href="/EditProfile/">Edit Profil</a></li>
              <li><a className="dropdown-item" href="https://wa.me/6285811497662">Daftar Premium</a></li>
              <li><button className="dropdown-item" onClick={LogoutApp}>Logout</button></li>
            </ul>
            )}
          </div>

          )
        :(
          <div className="dropdown">
          <button className={`btn ${isBoxCollapseOpen ? 'navbar-toggle-box-collapse' : ''}`} rel="noopener noreferrer" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="true">
            <i className="bi bi-box-arrow-right" style={{ 'WebkitTextStroke': '1px' }}></i>
          </button>
          {ariaControlsValue ?(
          <ul className='dropdown-menu dropdown-menu-start'>
            <li><a className="dropdown-item" href="/EditProfile/">Edit Profil</a></li>
            <li><button className="dropdown-item" onClick={LogoutApp}>Logout</button></li>
          </ul>
          ):(
          <ul className='dropdown-menu dropdown-menu-end'>
            <li><a className="dropdown-item" href="/EditProfile/">Edit Profil</a></li>
            <li><button className="dropdown-item" onClick={LogoutApp}>Logout</button></li>
          </ul>
          )}
        </div>
        )
        }
        </div>
      </nav>
    </>
  );
};

export default Navbar;