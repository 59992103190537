// Import the functions you need from the SDKs you need
import { initializeApp, setLogLevel } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFUYzo3uxdMvcG3cZiZXJIrelyGi2lCWI",
  authDomain: "realestate-43408.firebaseapp.com",
  databaseURL: "https://realestate-43408-default-rtdb.firebaseio.com",
  projectId: "realestate-43408",
  storageBucket: "realestate-43408.appspot.com",
  messagingSenderId: "1078101504682",
  appId: "1:1078101504682:web:3b4e167d8523fd572d56d3",
  measurementId: "G-2JR9Q8ET80"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
setLogLevel("silent");
const auth = getAuth(app);
const storage = getStorage(app);

export {app, auth, db, storage, analytics};