import React, {useState,useEffect} from "react";
import { db} from "../../firebase";
import { getDoc,getDocs, doc, collection, query, where,limit  } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import gogate1 from "./../../assets/img/gogate2.jpg";
import gogate2 from "./../../assets/img/gogate3.jpg";
import webgogate1 from "./../../assets/img/web gogate 2-01.jpg";
import webgogate2 from "./../../assets/img/web gogate 2-02.jpg";
import webgogate3 from "./../../assets/img/web gogate 2-03.jpg";
import { Akun } from "../../state/akun";
import parse from 'html-react-parser';
import numeral from "numeral";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import { TestimoniTotal } from "./premi";

const HalamanHome = () => {
    const [properties, setProperties] = useState([]);
    const [Penjual, setPenjual] = useState([]);
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
            const propertiesCollection = await getDocs(query(collection(db, "properti"),limit(3)));
            const propertiesData = propertiesCollection.docs.map((doc) => doc.data());
            setProperties(propertiesData);
            const PenjualCollection = await getDocs(query(collection(db, "Users"),where("role","==","Penjual"), limit(3)));
            const PenjualData = PenjualCollection.docs.map((doc) => doc.data());
            setPenjual(PenjualData);
            const BlogsCollection = await getDocs(query(collection(db, "Blogs"), limit(3)));
            const BlogsData = BlogsCollection.docs.map((doc) => doc.data());
            setBlogs(BlogsData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }, []);
    const maxLength = 50;
    return(
        <>
        <div className="intro intro-carousel" style={{paddingBottom:'2vh'}}>
            <Swiper
                style={{top:'10%',height: 'auto'}}
                // install Swiper modules
                modules={[Pagination]}
                slidesPerView={1}
                spaceBetween={0}
                autoplay={true}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper"
                >
                <SwiperSlide><img src={gogate1} style={{objectFit:'contain',maxWidth: '100%',height: 'auto'}} /></SwiperSlide>
                <SwiperSlide><img src={gogate2} style={{objectFit:'contain',maxWidth: '100%',height: 'auto'}} /></SwiperSlide>
        </Swiper>
        </div>
        <main id="main">
        <section className="section-services section-t8" >
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                        <h2 className="title-a">Layanan Kami</h2>
                    </div>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    
                <div class="card" style={{  margin: '0 0.5em',
                boxShadow: '2px 6px 8px 0 rgba(22, 22, 26, 0.18)',
                border: 'none',
                height: '100%',}}>
                    <div className="card-body">
                    <div className="card-header-c d-flex">
                        <div className="card-box-ico" >
                        <i className="bi bi-houses"/>
                        </div>
                        <br/>
                        <div className="card-title-c align-self-center">
                        <h2 className="title-c">Cari Properti</h2>
                        </div>
                    </div>
                    <div className="card-body-c">
                        <p className="content-c" style={{height:'100px'}}>
                        Temukan berbagai tipe rumah dengan desain modern, lokasi strategis, lingkungan aman dan nyaman serta harga yang cocok dengan budget Anda.
                        </p>
                    </div>
                    <div className="card-footer-c" style={{textAlign:'right',paddingRight:'20px'}}>
                        <a href="/Rumah/" className="link-c link-icon" style={{color:'#FFD700'}}>Lihat Selengkapnya
                        <i className="bi bi-chevron-right" />
                        </a>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4">
                <div class="card" style={{  margin: '0 0.5em',
                boxShadow: '2px 6px 8px 0 rgba(22, 22, 26, 0.18)',
                border: 'none',
                height: '100%',}}>
                    <div className="card-body">
                      
                    <div className="card-header-c d-flex">
                        <div className="card-box-ico">
                        <i className="bi bi-person"/>
                        </div>
                        <br/>
                        <div className="card-title-c align-self-center">
                        <h2 className="title-c">Cari Agen</h2>
                        </div>
                    </div>
                    <div className="card-body-c">
                        <p className="content-c" style={{height:'100px'}}>
                        Temukan agen terbaik di lokasi incaran Anda. Ajukan pertanyaan dan mulai obrolan untuk mencari solusi properti yang Anda butuhkan.
                        </p>
                    </div>
                    <div className="card-footer-c" style={{textAlign:'right',paddingRight:'20px'}}>
                        <a href="/agen" className="link-c link-icon" style={{color:'#FFD700'}}>Lihat Selengkapnya
                        <i className="bi bi-chevron-right" />
                        </a>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4">
              <div class="card" style={{  margin: '0 0.5em',
                boxShadow: '2px 6px 8px 0 rgba(22, 22, 26, 0.18)',
                border: 'none',
                height: '100%',}}>
                  <div className="card-body">
                    <div className="card-header-c d-flex">
                        <div className="card-box-ico">
                        <i className="bi bi-card-checklist"/>
                        </div>
                        <br/>
                        <div className="card-title-c align-self-center">
                        <h2 className="title-c">Simulasi KPR</h2>
                        </div>
                    </div>
                    <div className="card-body-c">
                        <p className="content-c" style={{height:'100px'}}>
                        Hitung simulasi kpr dan kalkulator kredit rumah dengan akurat dan mudah.
                        </p>
                    </div>
                    <div className="card-footer-c" style={{textAlign:'right',paddingRight:'20px'}}>
                        <a href="/SimulasiKPR" className="link-c link-icon" style={{color:'#FFD700'}}>Lihat Selengkapnya
                        <i className="bi bi-chevron-right" />
                        </a>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section className="section-property section-t8">
        <div className="container">
            <div className="row">
            <div className="col-md-12">
                <div className="title-wrap d-flex justify-content-between">
                <div className="title-box">
                    <h2 className="title-a">Properti Terpopuler</h2>
                </div>
                <div className="title-link">
                    <a href="/Rumah">
                    Lihat Semua
                    <span className="bi bi-chevron-right"></span>
                    </a>
                </div>
                </div>
            </div>
            </div>

            <div id="property-carousel" className="swiper">
                <div className="row">
                {properties.length > 0 &&
            properties.map((property, index) => (
            <a href={'/Rumah/'+property.id} key={index}> 
              <div className="col-md-4">
                <div className="card-box-a card-shadow">
                  <div className="img-box-a">
                    <img src={property?.fotorumah?.[0]?.urldownload} alt="" style={{objectFit:'cover'}} className="img-a img-fluid"/>
                  </div>
                  <div className="card-overlay">
                    <div className="card-overlay-a-content">
                      <div className="card-header-a">
                        <h2 className="card-title-a">
                          <a href={'/Rumah/'+property.id}>{property.nama}</a>
                        </h2>
                      </div>
                      <div className="card-body-a">
                        <div className="price-box d-flex">
                          <span className="price-a">Rp {numeral(property.harga).format('0,0')}</span>
                        </div>
                        <a href={'/Rumah/'+property.id} className="link-a">Click here to view
                          <span className="bi bi-chevron-right"></span>
                        </a>
                      </div>
                      <div className="card-footer-a">
                        <ul className="card-info d-flex justify-content-around">
                          <li>
                            <h4 className="card-info-title">Area</h4>
                            <span>{property.area.luasTanah} m 
                              <sup>2</sup>
                            </span>
                          </li>
                          <li>
                            <h4 className="card-info-title">Beds</h4>
                            <span>{property.kamarTidur}</span>
                          </li>
                          <li>
                            <h4 className="card-info-title">Baths</h4>
                            <span>{property.kamarMandi}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </a>
            ))}
                </div>
            </div>
            <div className="propery-carousel-pagination carousel-pagination"></div>
        </div>
        </section>
        <section className="section-agents section-t8">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                        <h2 className="title-a">Agen Properti</h2>
                    </div>
                    <div className="title-link">
                        <a href="/Agen">Lihat Semua
                        <span className="bi bi-chevron-right"></span>
                        </a>
                    </div>
                </div>
                        </div>
                        </div>
                        <div className="row">
                        { Penjual.length > 0 &&
            Penjual.map((penjual, index) => (
              <div className="col-md-4" key={index}>
                <div className="card-box-d">
                  <div className="card-img-d">
                    <img src={penjual.fotourl} alt="" className="img-d img-fluid"/>
                  </div>
                  <div className="card-overlay card-overlay-hover">
                    <div className="card-header-d">
                      <div className="card-title-d align-self-center">
                        <h3 className="title-d">
                          <a href={"/Agen/"+penjual.uid} className="link-two">{penjual.nama}</a>
                        </h3>
                      </div>
                    </div>
                    <div className="card-body-d">
                      <p className="content-d color-text-a">
                        {penjual.deskripsi_pribadi}
                      </p>
                      <div className="info-agents color-a">
                        <p>
                          <strong>Phone: </strong> <a href={"https://wa.me/62"+penjual.telepon}>{penjual.telepon}</a>
                        </p>
                        <p>
                          <strong>Email: </strong> {penjual.email}
                        </p>
                      </div>
                    </div>
                    <div className="card-footer-d">
                      <div className="socials-footer d-flex justify-content-center">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a href={"https://facebook.com/"+penjual.facebook} className="link-one">
                              <i className="bi bi-facebook" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={"https://instagram.com/"+penjual.instagram} className="link-one">
                              <i className="bi bi-instagram" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={"https://linkedin.com/"+penjual.linkedin} className="link-one">
                              <i className="bi bi-linkedin" aria-hidden="true"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                          ))}
                </div>
            </div>
        </section>
        <section className="section-news section-t8">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                        <h2 className="title-a">Berita Terbaru</h2>
                    </div>
                    <div className="title-link">
                        <a href="/Blog">Lihat Semua
                        <span className="bi bi-chevron-right"></span>
                        </a>
                    </div>
                    </div>
                </div>
                </div>

                <div id="news-carousel" className="swiper">
                <div className="swiper-wrapper">
                <div className="row">
                {blogs.map((blog, index) => (
              <div className="col-md-4" key={index}>
              <div class="carousel-item active">
              <div class="card" style={{  margin: '0 0.5em',
                boxShadow: '2px 6px 8px 0 rgba(22, 22, 26, 0.18)',
                border: 'none',
                borderRadius:'10px',
                height: '100%',}}>
                <div class="img-wrapper">
                  <img src={blog.imageUrl} class="d-block w-100"  style={{objectFit:'cover'}} alt="..."/> 
                </div>
                <div class="card-body">
                <h5 className="card-title" style={{color:'#000000'}}>
                        <a href={'/Blog/'+blog.id} style={{color:'#000000',textDecoration:'none'}}>{blog.title}</a>
                      </h5>
                      <p>
                        {blog.content.length > maxLength ? parse(`${blog.content.substring(0, maxLength)}...`) : parse(blog.content)}
                      </p>
                </div>
              </div>
            </div>
              </div>
            ))}
                </div>

                </div>
                </div>

                <div className="news-carousel-pagination carousel-pagination"></div>
            </div>
        </section>
        <TestimoniTotal/>
        <section className="section-testimonials section-t8 nav-arrow-a">
                <Swiper
                        style={{top:'10%',height: 'auto'}}
                        // install Swiper modules
                        modules={[Pagination]}
                        slidesPerView={1}
                        spaceBetween={0}
                        autoplay={true}
                        loop={true}
                        pagination={{
                        clickable: true,
                        }}
                        className="mySwiper"
                        >
                        <SwiperSlide><img src={webgogate3} style={{objectFit:'contain',maxWidth: '100%',height: 'auto'}} /></SwiperSlide>
                </Swiper>
        </section>
        </main>
        </>
    );
}
const HalamanAdmin = () => {
    const [properties, setProperties] = useState([]);
    const [Penjual, setPenjual] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [pengunjung, setPengunjung] = useState({
        pengunjung_total:0,
        pengunjung_harian:0,
    });
    useEffect(() => {
      const fetchData = async () => {
        try {
            const propertiesCollection = await getDocs(query(collection(db, "properti"),limit(3)));
            const propertiesData = propertiesCollection.docs.map((doc) => doc.data());
            setProperties(propertiesData);
            const PenjualCollection = await getDocs(query(collection(db, "Users"),where("role","==","Penjual"), limit(3)));
            const PenjualData = PenjualCollection.docs.map((doc) => doc.data());
            setPenjual(PenjualData);
            const BlogsCollection = await getDocs(query(collection(db, "Blogs"), limit(3)));
            const BlogsData = BlogsCollection.docs.map((doc) => doc.data());
            setBlogs(BlogsData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
      getDoc(doc(db, "analisis","pengguna")).then((docs)=>{
        if (docs.exists()) {
          const data = docs.data();
          setPengunjung((prevData) => ({
              pengunjung_total:data.jumlah_pengunjung||0,
              pengunjung_harian:data.pengunjung_harian||0,
          }))
        }
  });
    }, []);
    return(
    <main id="main">
      <section className="intro-single">
        <div className="container">
          <div className="row">
            <a href='/Rumah'  className="col-xxl-4 col-md-4">
            <div>
              <div className="card info-card revenue-card">

                <div className="card-body">
                  <h5 className="card-title">Daftar Rumah yang dijual</h5>

                  <div className="d-flex align-items-center">
                    <div className="ps-3">
                      <h6>{properties.length} total properti</h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            </a>
            <a href="/user" className="col-xxl-4 col-md-4">
            <div >
              <div className="card info-card revenue-card">

                <div className="card-body">
                  <h5 className="card-title">Jumlah Penjual</h5>

                  <div className="d-flex align-items-center">
                    <div className="ps-3">
                      <h6>{Penjual.length} total penjual</h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            </a>
            <a href="/Blogs" className="col-xxl-4 col-md-4">
            <div >
            <div className="card info-card revenue-card">


              <div className="card-body">
                <h5 className="card-title">Kelola Blog</h5>

                <div className="d-flex align-items-center">
                  <div className="ps-3">
                    <h6>{blogs.length} total blog</h6>
                  </div>
                </div>
              </div>

            </div>
            </div>
            </a>
          </div>
          <div className="row" style={{marginTop:'10%'}}>
          <div className="col-xxl-6 col-md-6">
              <div className="card info-card revenue-card">

                <div className="card-body">
                  <h5 className="card-title">Jumlah Pengunjung total</h5>

                  <div className="d-flex align-items-center">
                    <div className="ps-3">
                      <h6>{pengunjung.pengunjung_total} pengunjung</h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-xxl-6 col-md-6">
              <div className="card info-card revenue-card">

                <div className="card-body">
                  <h5 className="card-title">Jumlah Pengunjung harian</h5>

                  <div className="d-flex align-items-center">
                    <div className="ps-3">
                      <h6>{pengunjung.pengunjung_harian} pengunjung</h6>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          </div>
      </section>
    </main>
    );
}
const HalamanPenjual= () =>{
  const navigate = useNavigate();
  
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
  const [properties, setProperties] = useState([]);
  const [penjual, setPenjual] = useState(
    {
      nama:'',
      email:'',
      telepon:'',
      instagram:'',
      linkedIn:'',
      facebook:'',
      fotourl:'',
      foto:'',
      deskripsi_pribadi:'',
      youtube:'',
      x:'',
    }
  );
  const jikaakuntidaklengkap = (nama) =>{
    window.alert("selamat datang "+ nama+" silahkan lengkapi profil anda terlebih dahulu");
    navigate('/EditProfile');
    navigate(0);
  }

  useEffect(() => {
    const fetchData = async () => {
        try {
            const propertiesCollection = await getDocs(query(collection(db, "properti"), where('uid', '==',uid),limit(3)));
            const propertiesData = propertiesCollection.docs.map((doc) => doc.data());
            setProperties(propertiesData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const lengkapiakun = () =>{
        try {
            const docRef = doc(db, "Users", uid);
            const docSnap = getDoc(docRef).then(async (doc)=>{
              if (doc.exists()) {
                const data = doc.data();
                setPenjual((prevData) => ({
                  ...prevData,
                  nama:data.nama||'',
                  email:data.email||'',
                  telepon:data.telepon||'',
                }));
                if(doc.exists() && data.telepon == null){
                  jikaakuntidaklengkap(data.nama);
                }
              } else {
                /* */
              }
            })
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }
    lengkapiakun();
    fetchData();
  }, []);
    return(        <>
        <div className="intro intro-carousel" style={{paddingBottom:'2vh'}}>
            <Swiper
                style={{top:'10%',height: 'auto'}}
                // install Swiper modules
                modules={[Pagination]}
                slidesPerView={1}
                spaceBetween={0}
                autoplay={true}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper"
                >
                <SwiperSlide><img src={webgogate2} style={{objectFit:'contain',maxWidth: '100%',height: 'auto'}} /></SwiperSlide>
        </Swiper>
        </div>
        <main id="main">
        <section className="section-services section-t8" >
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                        <h2 className="title-a">Layanan Kami</h2>
                    </div>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                    <div className="card-box-c foo">
                    <div className="card-header-c d-flex">
                        <div className="card-box-ico">
                        <span className="bi bi-calendar4-week"></span>
                        </div>
                        <div className="card-title-c align-self-center">
                        <h2 className="title-c">Iklankan Produk</h2>
                        </div>
                    </div>
                    <div className="card-body-c">
                        <p className="content-c">
                        Nulla porttitor accumsan tincidunt. Curabitur aliquet quam id dui posuere blandit. Mauris blandit
                        aliquet elit, eget tincidunt
                        nibh pulvinar a.
                        </p>
                    </div>
                    <div className="card-footer-c">
                        <a href="#" className="link-c link-icon">Read more
                        <span className="bi bi-calendar4-week"></span>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card-box-c foo">
                    <div className="card-header-c d-flex">
                        <div className="card-box-ico">
                        <span className="bi bi-card-checklist"></span>
                        </div>
                        <div className="card-title-c align-self-center">
                        <h2 className="title-c">Jual Produk</h2>
                        </div>
                    </div>
                    <div className="card-body-c">
                        <p className="content-c">
                        Sed porttitor lectus nibh. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa,
                        convallis a pellentesque
                        nec, egestas non nisi.
                        </p>
                    </div>
                    <div className="card-footer-c">
                        <a href="#" className="link-c link-icon">Read more
                        <span className="bi bi-chevron-right"></span>
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section className="section-property section-t8">
        <div className="container">
            <div className="row">
            <div className="col-md-12">
                <div className="title-wrap d-flex justify-content-between">
                <div className="title-box">
                    <h2 className="title-a">Properti ku</h2>
                </div>
                <div className="title-link">
                    <a href="/Rumah">
                    All Property
                    <span className="bi bi-chevron-right"></span>
                    </a>
                </div>
                </div>
            </div>
            </div>

            <div id="property-carousel" className="swiper">
            <div className="swiper-wrapper">
                <div className="carousel-item-b swiper-slide">
                { properties.length > 0 &&
           properties.map((property, index) => (
            <a href={'/Rumah/'+property.id} key={index}> 
              <div className="col-md-4">
                <div className="card-box-a card-shadow">
                  <div className="img-box-a">
                    <img src={property?.fotorumah?.[0]?.urldownload} alt="" style={{objectFit:'cover'}} className="img-a img-fluid"/>
                  </div>
                  <div className="card-overlay">
                    <div className="card-overlay-a-content">
                      <div className="card-header-a">
                        <h2 className="card-title-a">
                          <a href={'/Rumah/'+property.id}>{property.nama}</a>
                        </h2>
                      </div>
                      <div className="card-body-a">
                        <div className="price-box d-flex">
                          <span className="price-a">Rp {numeral(property.harga).format('0,0')}</span>
                        </div>
                        <a href={'/Rumah/'+property.id} className="link-a">Click here to view
                          <span className="bi bi-chevron-right"></span>
                        </a>
                      </div>
                      <div className="card-footer-a">
                        <ul className="card-info d-flex justify-content-around">
                          <li>
                            <h4 className="card-info-title">Area</h4>
                            <span>{property.area.luasTanah} m 
                              <sup>2</sup>
                            </span>
                          </li>
                          <li>
                            <h4 className="card-info-title">Beds</h4>
                            <span>{property.kamarTidur}</span>
                          </li>
                          <li>
                            <h4 className="card-info-title">Baths</h4>
                            <span>{property.kamarMandi}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </a>
            ))}
                </div>

                {/* Tambahkan carousel items lainnya disini */}

            </div>
            </div>
            <div className="propery-carousel-pagination carousel-pagination"></div>
        </div>
        </section>
        <section className="section-testimonials section-t8 nav-arrow-a">
                <Swiper
                        style={{top:'10%',height: 'auto'}}
                        // install Swiper modules
                        modules={[Pagination]}
                        slidesPerView={1}
                        spaceBetween={0}
                        autoplay={true}
                        loop={true}
                        pagination={{
                        clickable: true,
                        }}
                        className="mySwiper"
                        >
                        <SwiperSlide><img src={webgogate3} style={{objectFit:'contain',maxWidth: '100%',height: 'auto'}} /></SwiperSlide>
                </Swiper>
        </section>
        </main>
        </>);
}
export {HalamanAdmin, HalamanHome, HalamanPenjual};