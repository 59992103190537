import React from "react";
import Rumah3 from "../../assets/img/rumah3.jpeg";
import loginBanner from "../../assets/img/loginBanner.png";
import json from "../../assets/json/jsonagent.json";
import Daud_Muhammad_Dzulfikar from '../../assets/img/Daud Muhammad Dzulfikar.jpg';
import Muhammad_Ikmal from '../../assets/img/Muhammad Ikmal.jpg';
import Silmi_Khoiriyah from '../../assets/img/Silmi Khoiriyah.jpg';
import Hafidz_Alfauzan from '../../assets/img/Hafidz Alfauzan.jpg';
import Cameila_Alya_Yahya from '../../assets/img/Cameila Alya Yahya.jpg';
import Dhimas_Arief from '../../assets/img/Dhimas Arief.jpg';
import Sridida_Ramania from '../../assets/img/Sridida Ramania.jpg';
import Sekar_Dewi from '../../assets/img/Sekar Dewi.jpg';
import Robbie_Mulkie from '../../assets/img/Robbie Mulkie.jpg';
import Akhmad_Fadilah from '../../assets/img/Akhmad Fadilah.jpg';
import Ibnu_Harbi from '../../assets/img/Ibnu Harbi.jpg';
import Syahrur_Ramadhan from '../../assets/img/Syahrur Ramadhan.jpg';
import Arief_Maulana from '../../assets/img/Arief Maulana.jpg';
import Aulia_Sabrina from '../../assets/img/Aulia Sabrina.jpg';
import Zannabu_Afifah from '../../assets/img/Zannabu Afifah.jpg';

const AboutUs = () => {
  const datatim = [
    {"nama": "Daud Muhammad Dzulfikar", "jabatan": "Direktur"},
    {"nama": "Muhammad Ikmal", "jabatan": "Direktur Operasional"},
    {"nama": "Silmi Khoiriyah", "jabatan": "Finance"},
    {"nama": "Hafidz Alfauzan", "jabatan": "Kepala Produksi"},
    {"nama": "Cameila Alya Yahya", "jabatan": "Human Resources"},
    {"nama": "Dhimas Arief", "jabatan": "Content Creator"},
    {"nama": "Sridida Ramania", "jabatan": "Customer Relationship Management"},
    {"nama": "Sekar Dewi", "jabatan": "Customer Relationship Management"},
    {"nama": "Robbie Mulkie", "jabatan": "Digital Marketing"},
    {"nama": "Akhmad Fadilah", "jabatan": "Manajer Marketing"},
    {"nama": "Ibnu Harbi", "jabatan": "Kepala Marketing"},
    {"nama": "Syahrur Ramadhan", "jabatan": "Marketing Agency"},
    {"nama": "Arief Maulana", "jabatan": "Marketing Agency"},
    {"nama": "Aulia Sabrina", "jabatan": "Marketing Agency"},
    {"nama": "Zannabu Afifah", "jabatan": "Marketing Agency"}
];
    return(
        <main id="main">
        <section className="intro-single">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div  >
                  <h1 className="title-single center">Tentang Kami</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-about">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 position-relative">
                <div className="about-img-box">
                  <img src={Rumah3} alt="" className="img-fluid"/>
                </div>
                <div className="sinse-box">
                  <h3 className="sinse-title">Golden Gate
                    <span></span>
                    <br/> Since 2024
                  </h3>
                  <p>By RAS Realty</p>
                </div>
              </div>
              <div className="col-md-12 section-t8 position-relative">
                <div className="row">
                  <div className="col-md-6 col-lg-5">
                    <img src={loginBanner} alt="" className="img-fluid"/>
                  </div>
                  <div className="col-lg-2  d-none d-lg-block position-relative">
                    <div className="title-vertical d-flex justify-content-start">
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 section-md-t3">
                    <div className="title-box-d">
                      <h3 className="title-d">Apa itu 
                        <span className="color-d"> RAS </span>Realty ?
                      </h3>
                    </div>
                    <p className="color-text-a">
                    PT. Rampak Abhipraya Sejahtera merupakan perusahaan yang bergerak dalam bidang properti, 
                    yang dibentuk untuk membantu masyarakat Indonesia untuk memiliki hunian yang nyaman, aman serta 
                    mengikuti perkembangan zaman 
                    Memiliki tujuan menjadi perusahaan properti yang Inovatif, Amanah dan 
                    Kredibel yang di Landasi pada nilai-nilai luhur bangsa Indonesia 
                    </p>
                    <p className="color-text-a">
                    Berdasarkan Akta perusahaan, PT. Rampak Abhipraya Sejahtera diresmikan pada tanggal 20 Februari 2023.
                    Project pertama PT Rampak Abhipraya Sejahtera yaitu "Grand Haikal", Hunian berkonsep modern minimalis 
                    yang dibangun pada tanggal 27 Februari 2023 Jumlah Civitas hingga Januari 2024 Berjumlah 17 orang 
                    dengan rincian 9 orang office dan 8 orang Agency 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-agents section-t8">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-wrap d-flex justify-content-between">
                  <div className="title-box">
                    <h2 className="title-a">Tim Kami</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            {datatim.map((tim, index) => (
              <div className="col-md-4" style={{marginBottom:'1%'}}>
                <div className="card-box-a card-shadow">
                  <div className="img-box-a">
                    <img src={require(`../../assets/img/${tim.nama}.jpg`)} alt="" style={{width:'100%',height:'400px',objectFit:'cover'}} className="img-a img-fluid"/>
                  </div>
                  <div className="card-overlay">
                    <div className="card-overlay-a-content">
                      <div className="card-header-a">
                        <h2 className="card-title-a">
                          {tim.nama}
                        </h2>
                      </div>
                      <div className="card-body-a">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        ))}
              
                            
            </div>
          </div>
        </section>
        <section className="section-about" style={{margin:'10%'}}>
          <div className="container">
          <div class="card text-center">
              <div class="card-body">
                <h5 class="card-title">Siap Miliki Hunian Idaman</h5>
                <p class="card-text">Dapatkan Informasi Lengkap dan penawaran menarik seputar hunian idaman yang Anda inginkan</p>
                <a href="/Kontak" class="btn btn-warning">Hubungi Kami</a>
              </div>
          </div>
          </div>
        </section>
      </main>
    );
}
export default AboutUs;