import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { signOut } from "firebase/auth";
import { auth,db} from "./firebase";
import { updateDoc, doc,increment} from "firebase/firestore";
import { useEffect, useState } from "react";
import {createBrowserRouter,Route,RouterProvider,Routes,useNavigate,useParams,useLocation} from "react-router-dom";
import {Akun} from './state/akun';
import KontakKami from './components/halaman/kontakKami';
import {HalamanAdmin, HalamanHome, HalamanPenjual} from './components/halaman/home';
import {BlogSingle, Blogs, EditBlogs} from './components/halaman/blogs';
import AboutUs from './components/halaman/aboutus';
import Login from './components/halaman/login';
import {ProfilPenjual, EditPenjual, ListPenjual} from './components/halaman/penjual';
import {CariRumah, PropertiRumah, EditRumah} from './components/halaman/Rumah';
import SimulasiKPR from './components/halaman/simulasiKPR';
import Navbar from './components/layout/navbar';
import { EditProfilAdmin, EditProfilPenjual } from './components/halaman/editProfil';
import { TestimoniEdit, TestimoniTotal } from './components/halaman/premi';
import Footer from './components/layout/footer';

function Router() {
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  const [analisis1,setAnalisis] = Akun.useState("jumlahPengguna",{default: 0});
  const[routerrole,setrouterole] = useState([{ path: "*", Component: Root }]);
  if(analisis1 < 1){
    analisis();
    setAnalisis(1);
  }
  useEffect(() => {
    // This code will run after the role state is updated
    /* */
    if (role == "Admin") {
      setrouterole([{ path: "*", Component: Admin }]);
    } 
    else if (role == "Penjual") {
      setrouterole([{ path: "*", Component: Penjual }]);
    }
    else{
      setrouterole([{ path: "*", Component: Root }]);
    }
  }, [role]);
  return createBrowserRouter(routerrole);

  
};
async function analisis(){
    const docref = doc(db, 'analisis','pengguna');
    await updateDoc(docref, {
      jumlah_pengunjung: increment(1)
  });
}
async function analisis_harian(){
  const docref = doc(db, 'analisis','pengguna');
  await updateDoc(docref, {
    pengunjung_harian: increment(1)
});
}
export function App() {
  const routes = Router();
  
  return <RouterProvider router={routes} />;
}

function Admin(){
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  // 2️⃣ `BrowserRouter` component removed, but the <Routes>/<Route>
  // component below are unchanged
  return (
    <>
      <Navbar role={role} />
      <Routes>
      <Route path='/Testimoni/' element={<TestimoniApp/>} />
      <Route path='/Testimoni/:idTestimoni' element={<EditTestimoniApp/>} />
      <Route path="/" element={<Home />} />
      <Route path="user/" element={<UserApp />} />
      <Route path="Agen/:uidParam" element={<UserEditApp />} />
      <Route path="/Rumah/" element={<RumahApp />} />
      <Route path="/Rumah/:idrumah" element={<PropertiApp />} />
      <Route path="/Blog/" element={<BlogApp />} />
      <Route path="/Blog/:idblog" element={<BlogSingleApp />} />
      <Route path="/login/*" element={<LoginApp />} />
      <Route path="/logout/*" element={<LogoutApp />} />
    </Routes>
      <Footer />
    </>
  );
}
function Penjual(){
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  // 2️⃣ `BrowserRouter` component removed, but the <Routes>/<Route>
  // component below are unchanged
  return (
    <>
      <Navbar role={role} />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Rumah/"  element={<RumahApp />} />
      <Route path="/Rumah/:idrumah" element={<PropertiApp />} />
      <Route path="/EditProfile/*" element={<EditProfilApp />} />
      <Route path="/Highlights/" element={<RumahApp />} />
      <Route path="/Highlights/:idrumah" element={<RumahApp />} />
      <Route path="/login/*" element={<LoginApp />} />
      <Route path="/logout/*" element={<LogoutApp />} />
    </Routes>
    <Footer />
    </>
  );
}
function Root() {
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  const location = useLocation();
  // 2️⃣ `BrowserRouter` component removed, but the <Routes>/<Route>
  // component below are unchanged
  const showNavbar = !location.pathname.includes("/login");
  return (
    <>
      {showNavbar && <Navbar role={role} />}
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Rumah/" element={<RumahApp />} />
      <Route path="/Agen/" element={<AgenApp />}/>
      <Route path="/Agen/:idagen" element={<AgenSingleApp />}/>
      <Route path="/Rumah/:idrumah" element={<PropertiApp />} />
      <Route path="/tentangkami/" element={<TentangKamiApp />} />
      <Route path="/simulasiKPR/:hargarumah?/*" element={<KPRApp />} />
      <Route path="/login/*" element={<LoginApp />} />
      <Route path="/Blog/" element={<BlogApp />} />
      <Route path="/Blog/:idblog?" element={<BlogSingleApp />} />
      <Route path="/kontak/*" element={<ContactApp />} />
    </Routes>
    <Footer />
    </>
  );
}

function Home() {
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  if(role == "Admin"){
    return HalamanAdmin();
  }
  else if (role == "Penjual"){
    return HalamanPenjual();
  }
  else{
    return HalamanHome();
  }
}
function BlogApp() {
    return Blogs();
}
function AgenApp(){
  return ListPenjual();
}
function AgenSingleApp(){
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  const params = useParams();
  if (params.idagen) {
    const { idagen } = params.idagen;
    if(role == "Admin"){
      return EditPenjual(idagen);
    }
    else{
    return ProfilPenjual(idagen);
    }
  } else {
    AgenApp();
  }
}
function BlogSingleApp(){
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  const params = useParams();
  if (params.idblog) {
    const { idblog } = params.idblog;
    if(role == "Admin"){
      return EditBlogs(idblog);
    }
    else{
    return BlogSingle(idblog);
    }
    
  } else {
    BlogApp();
  }
}
function PropertiApp(){
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  let params = useParams();
  if (params.idrumah) {
    const { idrumah } = params.idrumah;
    if(role != "Pembeli"){
      return EditRumah(idrumah);
    }
    else{
      return PropertiRumah(idrumah);
    }
  } else {
    RumahApp();
  }
}
function UserEditApp(){
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  let params = useParams();
  if (params.uidParam) {
    const { uidParam } = params.uidParam;
    if(role == "Admin"){
      return EditProfilPenjual(uidParam);
    }
    else{
      return ProfilPenjual(uidParam);
    }
  }else{
  
    return AgenApp();
  }
}
function RumahApp() {
    return CariRumah();
}
function UserApp(){
  return ListPenjual();
}
function LoginApp() {
  let navigate = useNavigate();
  const[uid, setUid] = Akun.useState("uid" ,{default: "Pembeli"});
  if(uid != "Pembeli"){
      navigate("/",{replace: true});
  }
  else{
    return Login();
  }
}
function LogoutApp(){
  const navigate = useNavigate();
  const[uid, setUid] = Akun.useState("uid", {default: "Pembeli"});
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  useEffect(() => {
      const handleLogout = async () => {
        try {
          await signOut(auth).then(() => {
            setUid("Pembeli", {persist:true});
            setRole("Pembeli", {persist:true});
            navigate("/" ,{replace: true});
          }).catch((error) => {
            alert(error)
            navigate(0);
          });
        } catch (error) {
          console.error('Logout error:', error);
           navigate(0);
          // Handle error, log it, or display a user-friendly message
        }};
  
      // Call the handleLogout function when the component mounts
      handleLogout();
    }, [navigate]);
  
    // You can render a loading indicator or other content if needed
    return (
      <div>Loading...</div>
    );
}

function EditProfilApp(){
  const[role, setRole] = Akun.useState("role", {default: "Pembeli"});
  if(role != "Pembeli"){
    if(role == "Penjual"){
      return EditProfilPenjual();
    }
    else{
      return EditProfilAdmin();
    }
  }
  else{
    return Login();
  }
}
function TentangKamiApp() {
  return AboutUs();
}
function KPRApp() {
  let params = useParams();
  if (params.hargarumah) {
    const { hargarumah } = params.hargarumah;
    return SimulasiKPR();
  } else {
    return SimulasiKPR();
  }
  
}
function ContactApp() {
  return KontakKami();
}
function EditTestimoniApp(){
  let params = useParams();
  if (params.idTestimoni) {
    const { idTestimoni } = params.idTestimoni;
    return TestimoniEdit();
  } else {
    return TestimoniApp();
  }
}
function TestimoniApp(){
  return TestimoniTotal();
}
export default App;
