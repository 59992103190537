import logowebsite from '../../assets/img/logobulat.png';
import React from "react";

const Footer = ()=>{
 return(
    <section  className="section-footer">

  <footer  className="bg-body-tertiary text-center text-md-start">
     
    <div  className="container p-4">
       
      <div  className="row">
      <div  className="col-lg-3 col-md-12 mb-4 mb-md-0"  >
        <h5><img src={logowebsite} alt="Logo" style={{ height: '200px' }} /></h5>
        </div>

        <div  className="col-lg-5 col-md-12 mb-4 mb-md-0 ">
        <h5> Golden Gate Properti</h5>
        <p>
            Jl Kasiba Lasiba, Kampung Kandang RT-03 RW-02 
            <br/>Kelurahan Duren Seribu, Kecamatan Bojongsari
            <br/>Depok, Jawa Barat.
            <br/>16516
        </p>
            <a href="https://wa.me/6285715474054"  className="btn btn-success"><i className="bi bi-whatsapp" aria-hidden="true"> Whatsapp</i></a>
        </div>
        

         
        <div  className="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5  className="text-uppercase">Menu</h5>

          <ul  className="list-unstyled mb-0">
            <li>
              <a href="/tentangkami"  className="text-body" style={{textDecoration:'none'}}>Tentang Kami</a>
            </li>
            <li>
              <a href="/Rumah"  className="text-body" style={{textDecoration:'none'}}>Cari Rumah</a>
            </li>
            <li>
              <a href="/Agen"  className="text-body" style={{textDecoration:'none'}}>Cari Agen</a>
            </li>
            <li>
              <a href="/SimulasiKPR"  className="text-body" style={{textDecoration:'none'}}>Simulasi KPR</a>
            </li>
            <li>
              <a href="/Kontak"  className="text-body" style={{textDecoration:'none'}}>Kontak Kami</a>
            </li>
          </ul>
        </div>
         

         
        <div  className="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5  className="text-uppercase ">Social Media</h5>

          <ul  className="list-unstyled mb-0">
            <li>
              <a href="https://www.facebook.com/profile.php?id=61551047473097" className="text-body" style={{textDecoration:'none'}}>Facebook</a>
            </li>
            <li>
              <a href="https://www.instagram.com/gogatepro/"  className="text-body" style={{textDecoration:'none'}}>Instagram</a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@golden.official.id"  className="text-body" style={{textDecoration:'none'}}>TikTok</a>
            </li>
            <li>
              <a href="https://www.youtube.com/@goldengateproperti"  className="text-body" style={{textDecoration:'none'}}>Youtube</a>
            </li>
          </ul>
        </div>
         
      </div>
       
    </div>
     

     
    <div  className="text-center p-3">
      © 2024 Copyright: Gogateproperti
    </div>
     
  </footer>
   
</section>
 );
}
export default Footer;