
import React, { useState, useEffect, useRef } from "react";// Sesuaikan dengan library yang Anda gunakan
import { db, storage } from "../../firebase";
import { getDoc,getDocs, doc, collection, setDoc,deleteDoc, query, where  } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Akun } from "../../state/akun";
import { ref, uploadBytes,getDownloadURL, deleteObject } from "firebase/storage";
import copy from "copy-to-clipboard";
import numeral from 'numeral';
const ListPenjual = () => {
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
  const [Penjual, setPenjual] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PenjualPerPage] = useState(4);

  useEffect(() => {
    const fetchData = async () => {
      try {
          const PenjualCollection = await getDocs(query(collection(db, "Users"),where("role","==","Penjual")));
          const PenjualData = PenjualCollection.docs.map((doc) => doc.data());
          setPenjual(PenjualData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const indexOfLastPenjual = currentPage * PenjualPerPage;
  const indexOfFirstPenjual = indexOfLastPenjual - PenjualPerPage;
  const currentPenjual = Penjual.slice(indexOfFirstPenjual, indexOfLastPenjual);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return(
        <main id="main">
        <section className="intro-single">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div  >
                  <h1 className="title-single">Agen Properti</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="agents-grid grid">
          <div className="container">
            <div className="row">
            { currentPenjual.length > 0 &&
            currentPenjual.map((penjual, index) => (
              <div className="col-md-4" key={index}>
                <div className="card-box-d">
                  <div className="card-img-d">
                    <img src={penjual.fotourl} alt=""  style={{width:'300px',height:'auto',objectFit: 'cover'}}/>
                  </div>
                  <div className="card-overlay card-overlay-hover">
                    <div className="card-header-d">
                      <div className="card-title-d align-self-center">
                        <h3 className="title-d">
                          <a href={"/Agen/"+penjual.uid} className="link-two">{penjual.nama}</a>
                        </h3>
                      </div>
                    </div>
                    <div className="card-body-d">
                      <p className="content-d color-text-a">
                        {penjual.deskripsi_pribadi}
                      </p>
                      <div className="info-agents color-a">
                        <p>
                          <strong>Phone: </strong> {penjual.telepon}
                        </p>
                        <p>
                          <strong>Email: </strong> {penjual.email}
                        </p>
                      </div>
                    </div>
                    <div className="card-footer-d">
                      <div className="socials-footer d-flex justify-content-center">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                          <a href={"https://tiktok.com/"+penjual.tiktok} className="link-one">
                              <i className="bi bi-tiktok" aria-hidden="true"></i>
                              </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={"https://facebook.com/"+penjual.facebook} className="link-one">
                              <i className="bi bi-facebook" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={"https://instagram.com/"+penjual.instagram} className="link-one">
                              <i className="bi bi-instagram" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={"https://linkedin.com/"+penjual.linkedin} className="link-one">
                              <i className="bi bi-linkedin" aria-hidden="true"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                          ))}
            </div>
            <div className="row">
              <div className="col-sm-12">
                <nav className="pagination-a">
                <ul className="pagination justify-content-end">
            <li className={`page-item ${currentPage === 1 && "disabled"}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="bi bi-chevron-left"></span>
              </button>
            </li>
            {Array.from({ length: Math.ceil(Penjual.length / PenjualPerPage) }, (_, i) => (
              <li
                key={i}
                className={`page-item ${currentPage === i + 1 && "active"}`}
              >
                <button className="page-link" onClick={() => paginate(i + 1)}>
                  {i + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${currentPage === Math.ceil(Penjual.length / PenjualPerPage) && "disabled"}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(Penjual.length / PenjualPerPage)}
              >
                <span className="bi bi-chevron-right"></span>
              </button>
            </li>
          </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
    
      </main>
    );
}
const ProfilPenjual = () =>{
  const params = useParams();
  const  userID = params.idagen;
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
  const [properties, setProperties] = useState([]);
  const [penjual, setPenjual] = useState(
    {
      nama:'',
      email:'',
      telepon:'',
      instagram:'',
      linkedIn:'',
      facebook:'',
      fotourl:'',
      foto:'',
      deskripsi_pribadi:'',
      youtube:'',
      x:'',
    }
  );
  useEffect(() => {
    try {
      const docRef = doc(db, "Users", userID);
      const docSnap = getDoc(docRef).then(async (doc)=>{
        if (doc.exists()) {
          const data = doc.data();
          setPenjual((prevData) => ({
            ...prevData,
            nama:data.nama||'',
            email:data.email||'',
            telepon:data.telepon||'',
            instagram:data.instagram||'',
            linkedIn:data.linkedIn||'',
            facebook:data.facebook||'',
            fotourl:data.fotourl||'',
            deskripsi_pribadi:data.deskripsi_pribadi||'',
            youtube:data.youtube||'',
            x:data.x||'',
          }));
          try {
            const propertiesCollection = await getDocs(query(collection(db, "properti"), where("uid", "==", userID)));
            const propertiesData = propertiesCollection.docs.map((doc) => doc.data());
            setProperties(propertiesData);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          /* */
        }
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [userID]);
  
  return(
      <main id="main">
          <section className="intro-single">
          <div className="container">
              <div className="row">
              <div className="col-md-12 col-lg-8">
                  <div  >
                  <h1 className="title-single">{penjual.nama}</h1>
                  <span className="color-text-a">Agen Properti</span>
                  </div>
              </div>
              <div className="col-md-12 col-lg-3">
              <div className="container center" style={{marginTop:"10%",marginLeft:"50%"}}>
              <button onClick={() =>{copy(window.location.href);
                alert("link telah disalin di clipboard");
            }} className='btn btn-warning right'><b><i className="bi bi-share-fill"/> bagikan</b></button>
              </div>
              </div>
              </div>
          </div>
          </section>
          <section className="agent-single">
          <div className="container">
              <div className="row">
              <div className="col-sm-12">
                  <div className="row">
                  <div className="col-md-6">
                      <div className="agent-avatar-box">
                      <img src={penjual.fotourl} alt="" className="agent-avatar img-fluid"/>
                      </div>
                  </div>
                  <div className="col-md-5 section-md-t3">
                      <div className="agent-info-box">
                      <div className="agent-title">
                          <div className="title-box-d">
                          <h3 className="title-d">{penjual.nama}
                          </h3>
                          </div>
                      </div>
                      <div className="agent-content mb-3">
                          <p className="content-d color-text-a">
                          {penjual.deskripsi_pribadi}
                          </p>
                          <div className="info-agents color-a">
                          <p>
                              <strong>Phone: </strong>
                              <span className="color-text-a"><a href={"https://wa.me/62"+penjual.telepon}> {penjual.telepon}</a></span>
                          </p>
                          <p>
                              <strong>Email: </strong>
                              <span className="color-text-a"><a href={"mailto:"+penjual.email}>{penjual.email}</a></span>
                          </p>
                          </div>
                      </div>
                      <div className="socials-footer">
                          <ul className="list-inline">
                          <li className="list-inline-item">
                            <a href={"https://facebook.com/"+penjual.facebook} className="link-one">
                            <i className="bi bi-facebook" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                              <a href={"https://tiktok.com/"+penjual.tiktok} className="link-one">
                              <i className="bi bi-tiktok" aria-hidden="true"></i>
                              </a>
                          </li>
                          <li className="list-inline-item">
                              <a href={"https://instagram.com/"+penjual.instagram} className="link-one">
                              <i className="bi bi-instagram" aria-hidden="true"></i>
                              </a>
                          </li>
                          <li className="list-inline-item">
                              <a href={"https://linkedin.com/"+penjual.linkedIn} className="link-one">
                              <i className="bi bi-linkedin" aria-hidden="true"></i>
                              </a>
                          </li>
                          </ul>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              <div className="col-md-12 section-t8">
                  <div className="title-box-d">
                  <h3 className="title-d">My properties </h3>
                  </div>
              </div>
              <div className="row property-grid grid">
                  <div className="col-sm-12">
                  <div className="grid-option">
                      <form>
                      <select className="custom-select">
                          <option selected>All</option>
                          <option value="1">New to Old</option>
                          <option value="2">For Rent</option>
                          <option value="3">For Sale</option>
                      </select>
                      </form>
                  </div>
                  </div>
                  { properties.map((property, index) => (
                    <a href={'/Rumah/'+property.id} key={index}> 
                      <div className="col-md-4">
                        <div className="card-box-a card-shadow">
                          <div className="img-box-a">
                            <img src={property?.fotorumah?.[0]?.urldownload} alt="" style={{objectFit:'cover'}} className="img-a img-fluid"/>
                          </div>
                          <div className="card-overlay">
                            <div className="card-overlay-a-content">
                              <div className="card-header-a">
                                <h2 className="card-title-a">
                                  <a href={'/Rumah/'+property.id}>{property.nama}</a>
                                </h2>
                              </div>
                              <div className="card-body-a">
                                <div className="price-box d-flex">
                                  <span className="price-a">Rp {numeral(property.harga).format('0,0')}</span>
                                </div>
                                <a href={'/Rumah/'+property.id} className="link-a">Click here to view
                                  <span className="bi bi-chevron-right"></span>
                                </a>
                              </div>
                              <div className="card-footer-a">
                                <ul className="card-info d-flex justify-content-around">
                                  <li>
                                    <h4 className="card-info-title">Area</h4>
                                    <span>{property.area.luasTanah} m 
                                      <sup>2</sup>
                                    </span>
                                  </li>
                                  <li>
                                    <h4 className="card-info-title">Beds</h4>
                                    <span>{property.kamarTidur}</span>
                                  </li>
                                  <li>
                                    <h4 className="card-info-title">Baths</h4>
                                    <span>{property.kamarMandi}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </a>
                    ))}
              </div>
              </div>
          </div>
          </section>

</main>
  );
}
const EditPenjual = () => {
  const params = useParams();
  const  userID = params.userid;
  const [Penjual, setPenjual] = useState(
    {
      nama:'',
      email:'',
      telepon:'',
      instagram:'',
      linkedIn:'',
      facebook:'',
      fotourl:'',
      foto:'',
      deskripsi_pribadi:'',
      youtube:'',
      x:'',
    }
  )
  useEffect(() => {
    try {
      const docRef = doc(db, "user", userID);
      const docSnap = getDoc(docRef).then(async (doc)=>{
        if (doc.exists()) {
          const data = doc.data();
          setPenjual((prevData) => ({
            ...prevData,
            nama:data.nama||'',
            email:data.email||'',
            telepon:data.telepon||'',
            instagram:data.instagram||'',
            linkedIn:data.linkedIn||'',
            facebook:data.facebook||'',
            fotourl:data.fotourl||'',
            deskripsi_pribadi:data.deskripsi_pribadi||'',
            youtube:data.youtube||'',
            x:data.x||'',
          }));
        } else {
          /* */
        }
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [userID]);


  return (
    <main id="main">
      <section className="news-single nav-arrow-b" style={{ marginTop: "10%" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div  >
                <h1 className="title-single">Penjual Editor untuk Admin</h1>
              </div>
               <form>{/* onSubmit={handleFormSubmit}> */}
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">judul dari artikel</label>
                  <input type="judul" className="form-control" value={Penjual.title} id="exampleFormControlInput1" required onChange={e => {
                    setPenjual((prevData) => ({...prevData,'title':e.target.value}));
                    /* */
              }}/>
                </div>
                {/* <button disabled={loading} className="btn btn-warning" type="submit">Save</button>
                <button className="btn btn-danger" onClick={(e) => {
                  e.preventDefault();
                  removeBlog();}}>Delete</button> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export {ProfilPenjual, EditPenjual, ListPenjual};