import React, { useState, useEffect, useRef } from "react";// Sesuaikan dengan library yang Anda gunakan
import { db, storage } from "../../firebase";
import { getDoc,getDocs, doc, collection, addDoc, setDoc, deleteDoc,query, limit } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Akun } from "../../state/akun";
import { ref, uploadBytes,getDownloadURL, deleteObject } from "firebase/storage";
import fotoprofil from "../../assets/img/Aulia Sabrina.jpg";
const TestimoniEdit =() => {
    const [Testimoni, setTestimoni] = useState({
        id:'',
        nama:'',
        foto:'',
        imageUrl:'',
        deskripsi:'',
    });
    const params = useParams();
    const navigate = useNavigate();
    const documentId = params.idTestimoni;
    const [loading,setLoading] = useState(false);
  
    const handleFileSelect = (file) => {
      try {
        /* */
        setLoading(true);
        setTestimoni((prevData) => ({
          ...prevData,
          'foto':String('Testimoni/'+Testimoni.id+'/'+file.name)
        }));
        /* */
        const imageRef = ref(storage,String('Testimoni/'+Testimoni.id+'/'+file.name));
        uploadBytes(imageRef, file).then(async (snapshot) => {
          /* */
          getDownloadURL(imageRef).then((url) => {
            setTestimoni((prevData) => ({
              ...prevData,
              'imageUrl':String(url),
            }));
          })
          
        });
  
      } catch (error) {
        console.error('Error uploading image to Firebase Storage:', error);
      }
      finally{
        setLoading(false);
      }
    };
  
    useEffect(() => {
      if(documentId != "tambah"){
        try {
          const docRef = doc(db, "Testimoni", documentId);
          const docSnap = getDoc(docRef).then(async (doc)=>{
            if (doc.exists()) {
              const data = doc.data();
              setTestimoni((prevData) => ({
                ...prevData,
                id: data.id || '',
                nama: data.nama || '',
                deskripsi: data.deskripsi || '',
                foto: data.foto || '',
                imageUrl: data.imageUrl || ''
              }));
            } else {
              /* */
            }
          })
        } catch (error) {
          console.error('Error fetching Testimoni data:', error);
        }
      }
      else{
        var randomString = require('random-string');
        setTestimoni((prevData) => ({...prevData,'id':randomString({length: 20})}));
      }
    }, [documentId]);
    const metadata = {
      contentType: 'image/jpeg'
    };
    const handleFormSubmit = async (e) => {
      e.preventDefault();
      try {
        if (documentId != 'tambah') {
          const Testimoniref = doc(db, 'Testimoni', documentId);
          await setDoc(Testimoniref, Testimoni);
        } else {
          const Testimoniref = doc(db, 'Testimoni', Testimoni.id);
          await setDoc(Testimoniref, Testimoni);
        }
        /* */
      } catch (error) {
        console.error('Error saving data:', error);
      } finally {
        navigate("/Testimoni/",{replace : true});
      }
    };
    const removeTestimoni = () =>{
      if (window.confirm("Delete the item?")) {
        handleRemovePhoto(Testimoni.foto)
        if(documentId!="tambah"){
          const docRef = doc(db, "Testimoni", documentId);
          deleteDoc(docRef).then(() => {
            /* */
            navigate("/Testimoni/")
          }).catch((error) => {
              console.error("Error removing document: ", error);
          });
        }
        
      }
    }
      const handleRemovePhoto = async (index) => {
      const desertRef = ref(storage, index);
      // Delete the file
      deleteObject(desertRef).then(() => {
        setTestimoni((databefore)=>(
          {...databefore,
            "foto":"",
            "imageUrl":""
        }));
        // File deleted successfully
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
    }; 
    
  
    return (
      <main id="main">
        <section className="news-single nav-arrow-b" style={{ marginTop: "10%" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <div  >
                  <h1 className="title-single">Testimoni Editor</h1>
                </div>
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3">
                    <label for="exampleFormControlInput1" className="form-label">Nama</label>
                    <input type="judul" className="form-control" value={Testimoni.nama} id="exampleFormControlInput1" required onChange={e => {
                      setTestimoni((prevData) => ({...prevData,'nama':e.target.value}));
                      /* */
                }}/>
                  </div>
                  <div className="input-group mb-3">
                    <input type="file" className="form-control" id="inputGroupFile02" accept="image/*" onChange={e => handleFileSelect(e.target.files[0])} />
                    <label className="input-group-text"  for="inputGroupFile02">Upload images</label>
                  </div>
                  <div className="mb-3">
                    <label for="exampleFormControlTextarea1" className="form-label">Isi</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={Testimoni.deskripsi} required onChange={e => {
                      setTestimoni((prevData) => ({...prevData,'deskripsi':e.target.value}));
                      /* */
                }} ></textarea>
                  </div>
                  <button disabled={loading} className="btn btn-warning" type="submit">Save</button>
                  <button className="btn btn-danger" onClick={(e) => {
                    e.preventDefault();
                    removeTestimoni();}}>Delete</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>)
    
}
const TestimoniTotal = () =>{ 
    const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
    const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
    const [blogs, setBlogs] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
            if(role == 'Admin'){
                const blogsCollection = await getDocs(collection(db, "Testimoni"));
                const blogsData = blogsCollection.docs.map((doc) => doc.data());
                setBlogs(blogsData);
            }
            else{
                const blogsCollection = await getDocs(query(collection(db, "Testimoni"),limit(3)));
                const blogsData = blogsCollection.docs.map((doc) => doc.data());
                setBlogs(blogsData);
            }
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);
    const styles = {
    fontFamily: 'Roboto, Arial, sans-serif',
    position: 'relative',
    overflow: 'hidden',
    margin: '10px',
    minWidth: '230px',
    maxWidth: '315px',
    width: '100%',
    color: '#000000',
    textAlign: 'center',
    fontSize: '16px',
    backgroundColor: '#FFD700',
    padding: '30px',
    backgroundImage: 'linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)',
    WebkitBoxSizing: 'border-box',
    boxSizing: 'border-box'
  };

  const innerStyles = {
    WebkitBoxSizing: 'border-box',
    boxSizing: 'border-box',
    WebkitTransition: 'all 0.35s ease',
    transition: 'all 0.35s ease'
  };

  const blockquoteStyles = {
    fontSize: '1em',
    padding: '45px 20px 40px 50px',
    marginTop: '30px',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    boxShadow: 'inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    position: 'relative'
  };

  const profileStyles = {
    objectFit: 'cover',
    width: '100px',
    borderRadius: '50%',
    display: 'inline-block',
    boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.4)',
    marginBottom: '10px',
    border: 'solid 5px #A6A57A'
  };

  const titleStyles = {
    fontWeight: 300,
    color: '#000000'
  };

  const subtitleStyles = {
    fontWeight: 300,
    color: '#000000'
  };

  return (
    <>
    <section className="intro-single">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8">
              <h1 className="title-single">Testimoni</h1>
          </div>
          <div className="col-md-12 col-lg-3">
          <div className="container center" style={{marginTop:"10%",marginLeft:"50%"}}>
          { role == 'Admin'?
            (<a href="/Testimoni/tambah" className='btn btn-warning right'>
            tambahkan
             </a>):(<>
             </>)
          }
          </div>
          </div>
        </div>
      </div>
    </section>
    <section className="news-grid grid">
    <div className="container">
      <div className="row">
      {blogs.map((blog, index) => (
              <div className="col-md-4">

                <div class="carousel-item active">
              <div class="card" style={{  margin: '0 0.5em',
                boxShadow: '2px 6px 8px 0 rgba(22, 22, 26, 0.18)',
                border: 'none',
                borderRadius:'10px',
                height: '100%',}}>
                <div class="img-wrapper">
                  <img src={blog.imageUrl} class="d-block w-100" style={{objectFit:'cover', width:"300px", height:"300px"}} alt="..."/> 
                </div>
                <div class="card-body">
                <h5 className="card-title" style={{color:'#000000'}}>
                      { role == 'Admin'?(
                        <a href={'/Testimoni/'+blog.id} style={{color:'#000000'}}>{blog.nama}</a>
                      ):(blog.nama)}
                      </h5>
                      { role == 'Admin'?(
                      <a href={'/Testimoni/'+blog.id} style={{color:'#000000'}}>Click here to Edit
                        <span className="bi bi-chevron-right"></span>
                      </a> ):(<p>
                        {blog.deskripsi}
                      </p>)}
                </div>
              </div>
            </div>
                </div>
      ))}
    </div>
    </div>
    </section>
    </>
  );
}


export {TestimoniEdit,TestimoniTotal}
