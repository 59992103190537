import React, { useState, useEffect, useRef } from "react";// Sesuaikan dengan library yang Anda gunakan
import { db, storage } from "../../firebase";
import { getDoc,getDocs, doc, collection, addDoc, setDoc, deleteDoc,query, where,limit } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Akun } from "../../state/akun";
import { ref, uploadBytes,getDownloadURL, deleteObject } from "firebase/storage";
import "jodit";
import "jodit/es5/jodit.min.css";
import JoditEditor from "jodit-react";
import parse from 'html-react-parser';
import copy from "copy-to-clipboard";
// Sesuaikan dengan konfigurasi Firebase Anda

const Blogs = () => {
  const[role, setRole] = Akun.useState("role",{default: "Pembeli"});
  const[uid, setUid] = Akun.useState("uid",{default: "Pembeli"});
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(4);
  const maxLength = 50;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogsCollection = await getDocs(collection(db, "Blogs"));
        const blogsData = blogsCollection.docs.map((doc) => doc.data());
        /* */
        setBlogs(blogsData);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <main id="main">
      <section className="intro-single">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div  >
                <h1 className="title-single">Artikel Blog</h1>
              </div>
            </div>
            <div className="col-md-12 col-lg-3">
            <div className="container center" style={{marginTop:"10%",marginLeft:"50%"}}>
            { role == 'Admin'?
              (<a href="/Blog/tambah" className='btn btn-warning right'>
              tambahkan blog
               </a>):(<>
               </>)
            }
            </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news-grid grid">
      <div className="container">
        <div className="row">
        {currentBlogs.map((blog, index) => (
              <div className="col-md-4">
              <div class="carousel-item active">
              <div class="card" style={{  margin: '0 0.5em',
                boxShadow: '2px 6px 8px 0 rgba(22, 22, 26, 0.18)',
                border: 'none',
                borderRadius:'10px',
                height: '100%',}}>
                <div class="img-wrapper">
                <img src={blog.imageUrl} class="d-block w-100" width="100%" height="auto" style={{objectFit:'cover',maxHeight:"300px"}} alt="..."/> 
                </div>
                <div class="card-body">
                <h5 className="card-title" style={{color:'#000000'}}>
                        <a href={'/Blog/'+blog.id} style={{color:'#000000',textDecoration:'none'}}>{blog.title}</a>
                      </h5>
                      { role == 'Admin'?(
                      <a href={'/Blog/'+blog.id} style={{color:'#000000'}}>Click here to Edit
                        <span className="bi bi-chevron-right"></span>
                      </a> ):(<p>
                        {blog.content.length > maxLength ? parse(`${blog.content.substring(0, maxLength)}...`) : parse(blog.content)}
                      </p>)}
                </div>
              </div>
            </div>
                </div>
      ))}
          </div>
        </div>
      </section>
      <div className="col-sm-12">
        <nav className="pagination-a">
          <ul className="pagination justify-content-end">
            <li className={`page-item ${currentPage === 1 && "disabled"}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="bi bi-chevron-left"></span>
              </button>
            </li>
            {Array.from({ length: Math.ceil(blogs.length / blogsPerPage) }, (_, i) => (
              <li
                key={i}
                className={`page-item ${currentPage === i + 1 && "active"}`}
              >
                <button className="page-link" onClick={() => paginate(i + 1)}>
                  {i + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${currentPage === Math.ceil(blogs.length / blogsPerPage) && "disabled"}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(blogs.length / blogsPerPage)}
              >
                <span className="bi bi-chevron-right"></span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </main>
  );
};
const BlogSingle = () => {
  const maxLength = 50;
  const params = useParams();
  const  documentId = params.idblog;
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blogData, setBlogData] = useState({
    id: '',
    title: '',
    category:'',
    date:'',
    imageUrl:'',
    author:'',
    content: '',
    foto:''
  });
 // Extract documentId from the route parameters

  useEffect(() => {
    try {
      const docRef = doc(db, "Blogs", documentId);
      const docSnap = getDoc(docRef).then(async (doc)=>{
        if (doc.exists()) {
          const data = doc.data();
          setBlogData((prevData) => ({
            ...prevData,
            id: data.id || '',
            title: data.title || '',
            category: data.category || '',
            date: data.date || '',
            imageUrl: data.imageUrl || '',
            author: data.author || '',
            content: data.content || '',
          }));
          const BlogsCollection = await getDocs(query(collection(db, "Blogs"), limit(4)));
          const BlogsData = BlogsCollection.docs.map((doc) => doc.data());
          setBlogs(BlogsData);
        } else {
          /* */
        }
      })
    } catch (error) {
      console.error('Error fetching blog data:', error);
    }
  }, [documentId]); // Include documentId in the dependency array

  return (
    <main id="main">
      <section className="news-single nav-arrow-b" >
        <div className="container" style={{marginTop:"10%"}}>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <div  >
                <h1 className="title-single">{ " "+ blogData.title}</h1>
              </div>
              <div className="post-information">
              <ul className="list-inline color-a">
              <li>
                  Date: 
                  <span className="color-text-a">{ " "+ blogData.date}</span>
                </li>
                <li className="list-inline-item mr-2">
                  Author: 
                  <span className="color-text-a">{" "+ blogData.author}</span>
                </li>
                <li className="list-inline-item mr-2">
                  Category: 
                  <span className="color-text-a">{ " "+ blogData.category}</span>
                </li>

                <li>
                <button onClick={() =>{copy(window.location.href);
                alert("link telah disalin di clipboard");
            }} className='btn btn-warning right'><b><i className="bi bi-share-fill"/> bagikan</b></button>
                </li>
              </ul>
            </div>
              <div className="col-sm-12">
              
            <div className="news-img-box">
              <img src={blogData.imageUrl} alt="" className="img-fluid"/>
            </div>
          </div>
          </div>
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            
            <div className="color-text-a">
            {parse(blogData.content)}
            </div>
            <div className="post-footer">
            </div>
          </div>
            </div>
          </div>
      </section>
      <section className="section-news section-t8">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="title-wrap d-flex justify-content-between">
                    <div className="title-box">
                        <h2 className="title-a">Artikel Lain</h2>
                    </div>
                    </div>
                </div>
                </div>

                <div id="news-carousel" className="swiper">
                <div className="swiper-wrapper">
                <div className="row">
                {blogs.map((blog, index) => (
              <div className="col-md-3" key={index}>
              <div class="carousel-item active">
              <div class="card" style={{  margin: '0 0.5em',
                boxShadow: '2px 6px 8px 0 rgba(22, 22, 26, 0.18)',
                border: 'none',
                borderRadius:'10px',
                height: '100%',}}>
                <div class="img-wrapper">
                  <img src={blog.imageUrl} class="d-block w-100"  style={{objectFit:'cover'}} alt="..."/> 
                </div>
                <div class="card-body">
                <h5 className="card-title" style={{color:'#000000'}}>
                        <a href={'/Blog/'+blog.id} style={{color:'#000000',textDecoration:'none'}}>{blog.title}</a>
                      </h5>
                      <p>
                        {blog.content.length > maxLength ? parse(`${blog.content.substring(0, maxLength)}...`) : parse(blog.content)}
                      </p>
                </div>
              </div>
            </div>
              </div>
            ))}
                </div>

                </div>
                </div>

                <div className="news-carousel-pagination carousel-pagination"></div>
            </div>
        </section>
    </main>
  );
};
const EditBlogs = () => {
  const params = useParams();
  const navigate = useNavigate();
  const documentId = params.idblog;
  const [loading,setLoading] = useState(false);
  const tanggal = new Date();
  const [blogData, setBlogData] = useState({
    id: '',
    title: '',
    category:'',
    date:tanggal.toLocaleString(),
    imageUrl:'',
    author:'',
    content: '',
    foto:''
  });

  const handleFileSelect = (file) => {
    try {
      /* */
      setLoading(true);
      setBlogData((prevData) => ({
        ...prevData,
        'foto':String('blogs/'+blogData.id+'/'+file.name)
      }));
      /* */
      const imageRef = ref(storage,String('blogs/'+blogData.id+'/'+file.name));
      uploadBytes(imageRef, file).then(async (snapshot) => {
        /* */
        getDownloadURL(imageRef).then((url) => {
          setBlogData((prevData) => ({
            ...prevData,
            'imageUrl':String(url),
          }));
        })
        
      });

    } catch (error) {
      console.error('Error uploading image to Firebase Storage:', error);
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    if(documentId != "tambah"){
      try {
        const docRef = doc(db, "Blogs", documentId);
        const docSnap = getDoc(docRef).then(async (doc)=>{
          if (doc.exists()) {
            const data = doc.data();
            setBlogData((prevData) => ({
              ...prevData,
              id: data.id || '',
              title: data.title || '',
              category: data.category || '',
              imageUrl: data.imageUrl || '',
              author: data.author || '',
              content: data.content || '',
              foto: data.foto || ''
            }));
          } else {
            /* */
          }
        })
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    }
    else{
      var randomString = require('random-string');
      setBlogData((prevData) => ({...prevData,'id':randomString({length: 20})}));
    }
  }, [documentId]);
  const metadata = {
    contentType: 'image/jpeg'
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (documentId != 'tambah') {
        const Blogref = doc(db, 'Blogs', documentId);
        await setDoc(Blogref, blogData);
      } else {
        const Blogref = doc(db, 'Blogs', blogData.id);
        await setDoc(Blogref, blogData);
      }
      /* */
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      navigate("/Blog/",{replace : true});
    }
  };
  const removeBlog = () =>{
    if (window.confirm("Delete the item?")) {
      handleRemovePhoto(blogData.foto)
      if(documentId!="tambah"){
        const docRef = doc(db, "Blogs", documentId);
        deleteDoc(docRef).then(() => {
          /* */
          navigate("/Blog/")
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
      }
      
    }
  }
    const handleRemovePhoto = async (index) => {
    const desertRef = ref(storage, index);
    // Delete the file
    deleteObject(desertRef).then(() => {
      setBlogData((databefore)=>(
        {...databefore,
          "foto":"",
          "imageUrl":""
      }));
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }; 
  

  return (
    <main id="main">
      <section className="news-single nav-arrow-b" style={{ marginTop: "10%" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div  >
                <h1 className="title-single">Artikel Editor</h1>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">judul dari artikel</label>
                  <input type="judul" className="form-control" value={blogData.title} id="exampleFormControlInput1" required onChange={e => {
                    setBlogData((prevData) => ({...prevData,'title':e.target.value}));
                    /* */
              }}/>
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">Author</label>
                  <input type="Author" className="form-control" value={blogData.author} id="exampleFormControlInput1" required onChange={e => {
                    setBlogData((prevData) => ({...prevData,'author':e.target.value}));
                    /* */
              }}/>
                </div>
                <select className="form-select form-select-md mb-3" value={blogData.category} aria-label=".form-select-md example" 
                onChange={e => {
                    setBlogData((prevData) => ({...prevData,'category':e.target.value}));
                    /* */
              }} required>
                  <option selected> silahkan pilih kategori </option>
                  <option value="Panduan">Panduan Properti</option>
                  <option value="Berita">Berita</option>
                  <option value="Tips and Trik">Tips and Trik</option>
                  <option value="Lifestyle">Lifestyle</option>
                  <option value="Pembiayaan">Pembiayaan</option>
                </select>
                <div className="input-group mb-3">
                  <input type="file" className="form-control" id="inputGroupFile02" accept="image/*" onChange={e => handleFileSelect(e.target.files[0])} />
                  <label className="input-group-text"  for="inputGroupFile02">Upload images</label>
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlTextarea1" className="form-label">Isi Artikel</label>
                  <JoditEditor className="form-control" id="exampleFormControlTextarea1" rows="3" value={blogData.content} required onChange={value => {
                    setBlogData((prevData) => ({...prevData,'content':value}));
                    /* */
              }} />
                </div>
                <button disabled={loading} className="btn btn-warning" type="submit">Save</button>
                <button className="btn btn-danger" onClick={(e) => {
                  e.preventDefault();
                  removeBlog();}}>Delete</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export {BlogSingle, Blogs, EditBlogs};
