import React, {useState} from "react";
import gambar from "./../../assets/img/loginBanner.png";
import {auth, db} from "./../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { signInWithEmailAndPassword,signInWithPopup,GoogleAuthProvider} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Akun } from "../../state/akun";

const Login = () => {
  const navigate = useNavigate();
  const[role, setRole] = Akun.useState("role");
  const[uid, setUid] = Akun.useState("uid");
  const [loading, setLoading] = useState(false);
  const loginGoogle = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider(auth);
    signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      const docRef = doc(db, "Users", result.user.uid);
      getDoc(docRef).then((doc) => {
        if (doc.exists()) {
            /* */
            setUid(user.uid , {persist:true});
            setRole(doc.data().role, {persist:true});
            navigate("/",{replace : true});
        } else {
          setDoc(docRef,{
            uid : user.uid,
            nama : user.displayName,
            email : user.email,
            role : "Penjual",
          },{ merge: true })
          .then(() => {
              setUid(user.uid , {persist:true});
              setRole("Penjual" , {persist:true});
              /* */
              navigate("/",{replace : true});
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
              alert(error.message);
          });
        }
      })
      .catch((error) => {
        setDoc(docRef,{
          uid : user.uid,
          nama : user.displayName,
          email : user.email,
          role : "Penjual",
        },{ merge: true })
        .then(() => {
            setUid(user.uid , {persist:true});
            setRole("Penjual" , {persist:true});
            /* */
            navigate("/",{replace : true});
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
            alert(error.message);
        });
      });
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      alert(errorMessage);
      // ...
    }).finally(() => {
      setLoading(false);
    });

  };

  const signInEmailAndPassword = (e) => {
    setLoading(true);
    e.preventDefault();
    const email = e.target.username.value;
    const password = e.target.password.value;
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      const docRef = doc(db, "Users", userCredential.user.uid);
      getDoc(docRef).then((doc) => {
        if (doc.exists()) {
          setUid(user.uid , {persist:true});
          setRole(doc.data().role , {persist:true});
          navigate("/",{replace : true});
        } else {
          setDoc(docRef,{
            email : user.email,
            role : "Admin",
          },{ merge: true })
          .then(() => {
            setUid(user.uid , {persist:true});
            setRole("Admin", {persist:true});
            /* */
            navigate("/",{replace : true});
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
              alert(error.message)
          });
        }
      })
      .catch((error) => {
        setDoc(docRef,{
          email : user.email,
          role : "Admin",
        },{ merge: true })
          .then(() => {
              setUid(user.uid , {persist:true});
              setRole("Admin" , {persist:true});
              /* */
              navigate("/",{replace : true});
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
              alert(error.message)
          });
        alert(error.message)
      }).finally(() => {
        setLoading(false);
      });

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage)
      setLoading(false);
    });
  };
  const styles = {
    content: {
      padding: '7rem 0',
    },
    h3: {
      marginBottom: '4px',
    },
    mb4: {
      marginBottom: '4px',
    },
    formGroup: {
      overflow: 'hidden',
      marginBottom: '0',
      padding: '15px 15px',
      borderBottom: 'none',
      position: 'relative',
      background: 'light-grey',
    },
    formGroupLabel: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      transition: '0.3s all ease',
      fontSize: '12px',
      display: 'block',
      marginBottom: '0',
      color: '#b3b3b3',
    },
    formGroupInput: {
      background: 'transparent',
      border: '1',
    },
    formGroupFirst: {
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
    },
    formGroupLast: {
      borderBottomLeftRadius: '7px',
      borderBottomRightRadius: '7px',
    },
    formControl: {
      border: 'none',
      padding: '0',
      fontSize: '20px',
      borderRadius: '0',
    },
    dFlex: {
      display: 'flex',
    },
    mb5: {
      marginBottom: '5px',
    },
    mlAuto: {
      marginLeft: 'auto',
    },
    btnBlock: {
      width: '100%',
    },
    dBlock: {
      display: 'block',
    },
    textLeft: {
      textAlign: 'center',
    },
    my4: {
      margin: '4px 4px',
    },
    FacebookLoginIcon: {
        borderRadius: "100%",
        background: "#3b5998",
        color:"#ffffff", // Set the background color
        padding: "20px", // Adjust the padding as needed
        margin: "15% 2%", // Add margin
    },
    GoogleLoginIcon: {
        textDecoration:'none',
        borderRadius: "10px",
        background: "#ea4335",
        color:"#ffffff", // Set the background color
        padding: "20px", // Adjust the padding as needed
        margin: "1% 2%", // Add margin
    },
  };

  return (
    <div style={styles.content}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={gambar} alt="Image" className="img-fluid" />
          </div>
          <div className="col-md-6 contents">
            <div className="row justify-content-center">
              <div>
                <div className="card" style={{height:'720px'}}>
                <div class="card-body"style={{margin:'5%'}}>

                <div style={styles.mb4}>
                  <h3 style={styles.h3}>Login/Register</h3>
                  <p style={styles.mb4}>Jika anda Admin. Silahkan login melalui email dan password</p>
                </div>
                <form onSubmit={signInEmailAndPassword}>
                  <div style={{ ...styles.formGroup, ...styles.formGroupFirst }}>
                    <label htmlFor="username" style={styles.formGroupLabel}></label>
                    <input type="email" className="form-control" disabled={loading} id="username" style={styles.formGroupInput} placeholder="Email" />
                  </div>
                  <div style={{ ...styles.formGroup, ...styles.formGroupLast }}>
                    <label htmlFor="password" style={styles.formGroupLabel}></label>
                    <input type="password" className="form-control" disabled={loading} id="password" style={styles.formGroupInput} placeholder="Password" autoComplete="on" />
                  </div>
                  <div style={styles.dFlex} className="align-items-center">
                    <span style={{ ...styles.mlAuto, ...styles.mb4 }}>
                      <a href="#" className="forgot-pass">Forgot Password</a>
                    </span>
                  </div>
                  {loading ? (
                    // Render loading animation
                    <div className="spinner-border text-warning" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    // Render the button
                    <input
                      type="submit"
                      value="Log In"
                      className="btn btn-block btn-warning"
                      style={styles.btnBlock}
                    />
                  )}
                  <span style={{ ...styles.dBlock, ...styles.textLeft, ...styles.my4 }}>&mdash; Atau jika Anda penjual. Silahkan login/daftar menggunakan tombol di bawah &mdash;</span>
                  <br/>
                  <div className="social-login text-center">
                    <a type="button" onClick={loginGoogle} className="google center" style={styles.GoogleLoginIcon}>
                      <i className="bi bi-google mr-3"/>
                      <span>  Login Penjual</span>
                    </a>
                  </div>
                </form>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
